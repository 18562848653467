/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file LoaderIcon.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import ThemeConstants from '../ThemeConstants';

const Styles = () => ({
  loaderIcon: {
    width: 32,
    height: 32
  },
  '@keyframes rotation': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(359deg)'
    }
  },
  loaderWrapper: {
    width: 32,
    margin: '0 auto',
    animation: '$rotation 2s infinite linear'
  }
});

const LoaderIcon = ({
  classes, masterTheme, theme, loderLabel
}) => (
  <div className={classes.loaderWrapper} aria-labelledby="spinner" aria-label={loderLabel}>
    <SvgIcon
      fill="none"
      viewBox="0 0 32 32"
      classes={{
        root: classes.loaderIcon
      }}
    >
      <rect
        width="32"
        height="32"
        rx="16"
        fill="#E8EEFA"
      />
      <path
        d="M21.2251 12.1337C22.0416 13.2371 22.4878 14.5707 22.4998 15.9433C22.5117 17.3159 22.0889 18.6571 21.2918 19.7746"
        stroke={ThemeConstants[masterTheme][theme].textColor}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M10.7749 12.1337C9.95844 13.2371 9.51223 14.5707 9.50025 15.9433C9.48827 17.3159 9.91114 18.6571 10.7082 19.7746"
        stroke={ThemeConstants[masterTheme][theme].textColor}
        strokeWidth="3"
        strokeLinecap="round"
      />
    </SvgIcon>
  </div>
);

LoaderIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string,
  loderLabel: PropTypes.string,
  masterTheme: PropTypes.string
};

LoaderIcon.defaultProps = {
  theme: 'default',
  loderLabel: '',
  masterTheme: 'plus'
};

export default withStyles(Styles)(LoaderIcon);

/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file TutorEyesHover.jsx
 * @author Arifhusain Soudagar
 */

import React from 'react';

const TutorEyesHover = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none" className="tutorEyes-active-inactive">
    <g filter="url(#filter0_d_5544_79851)">
      <rect x="8.5" y="16" width="13" height="16" rx="6" fill="#62FFEE" />
    </g>
    <g filter="url(#filter1_d_5544_79851)">
      <rect x="27.5" y="16" width="13" height="16" rx="6" fill="#62FFEE" />
    </g>
    <defs>
      <filter id="filter0_d_5544_79851" x="0.5" y="8" width="29" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.384314 0 0 0 0 1 0 0 0 0 0.933333 0 0 0 0.45 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5544_79851" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5544_79851" result="shape" />
      </filter>
      <filter id="filter1_d_5544_79851" x="19.5" y="8" width="29" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.384314 0 0 0 0 1 0 0 0 0 0.933333 0 0 0 0.45 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5544_79851" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5544_79851" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default TutorEyesHover;

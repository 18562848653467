/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file DownArrow.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';

const Styles = () => ({
  arrowStyle: {
    width: 54,
    height: 54,
    fill: 'none'
  }
});

const DownArrow = ({ classes }) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 54 54"
    classes={{
      root: classes.arrowStyle
    }}
  >
    <g filter="url(#filter0_ddd_243_18111)">
      <rect x="45.375" y="7.625" width="36.75" height="36.75" rx="18.375" transform="rotate(90 45.375 7.625)" fill="url(#paint0_linear_243_18111)" />
      <path d="M26.1249 20.0576V29.8314L21.8549 25.5614C21.5136 25.2201 20.9536 25.2201 20.6124 25.5614C20.2711 25.9026 20.2711 26.4539 20.6124 26.7951L26.3786 32.5614C26.7199 32.9026 27.2711 32.9026 27.6124 32.5614L33.3874 26.8039C33.7286 26.4626 33.7286 25.9114 33.3874 25.5701C33.0461 25.2289 32.4949 25.2289 32.1536 25.5701L27.8749 29.8314V20.0576C27.8749 19.5764 27.4811 19.1826 26.9999 19.1826C26.5186 19.1826 26.1249 19.5764 26.1249 20.0576Z" fill="#FEFEFE" />
    </g>
    <defs>
      <filter id="filter0_ddd_243_18111" x="-2" y="-2" width="58" height="58" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_243_18111" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_243_18111" result="effect2_dropShadow_243_18111" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect3_dropShadow_243_18111" />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_243_18111" result="effect3_dropShadow_243_18111" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_243_18111" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_243_18111" x1="45.375" y1="7.625" x2="82.125" y2="44.375" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EE512E" />
        <stop offset="0.634355" stopColor="#DF006B" />
        <stop offset="1" stopColor="#BD0071" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

DownArrow.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(Styles)(DownArrow);

/* eslint-disable no-return-assign */
/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file ReadMore.jsx
 * @author Sai Kiran
 */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import { Utilities } from '@aquila/core';
import ThemeConstants from '../../Common/ThemeConstants';
import * as constants from '../../Common/constants';
// import globalContext from '../../Context/globalContext';

const styles = () => ({
  readMoreWrapper: {
    fontSize: 16,
    fontStyle: 'normal',
    overflow: 'hidden',
    fontFamily: 'TT Commons',
    fontWeight: 400,
    lineHeight: '24px',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    '& h1, & h2, & h3, & h4, & h5, & h6': {
      fontSize: 16,
      fontStyle: 'normal',
      fontFamily: 'TT Commons',
      fontWeight: 700,
      lineHeight: '20px',
      color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
    },
    '& ul': {
      fontSize: '16px',
      paddingInlineStart: 20,
      margin: 0,
      lineHeight: '24px',
      listStyleType: 'disc',
      '& li': {
        fontSize: 16,
        fontFamily: 'TT Commons',
        color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
        marginBottom: 5
      }
    },
    '& ol': {
      fontSize: 16,
      paddingInlineStart: 20,
      margin: 0,
      lineHeight: '24px',
      listStyleType: 'decimal',
      '& li': {
        fontSize: 16,
        fontFamily: 'TT Commons',
        color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
        marginBottom: 5,
        display: 'list-item'
      }
    },
    '& p': {
      lineHeight: '24px'
    },
    '& p:last-child': {
      marginBottom: 0
    },
    '& p:first-child': {
      marginTop: 0
    },
    '& a': {
      fontSize: 14,
      fontFamily: 'TT Commons',
      color: '#4C406B',
      width: 16,
      height: 16,
      cursor: 'pointer',
      padding: 5,
      '&:hover': {
        textDecoration: 'none',
        color: '#4C406B',
        borderRadius: '50%',
        backgroundColor: '#D9D9D9'
      }
    },
    '& img': {
      width: '100%',
      marginTop: 10,
      cursor: 'pointer'
    },
    '& mjx-math': {
      whiteSpace: 'normal !important'
    }
  },
  readMoreWrapperForParagraph: {
    fontSize: 16,
    fontStyle: 'normal',
    fontFamily: 'TT Commons',
    fontWeight: 400,
    lineHeight: '24px',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    whiteSpace: 'pre-wrap'
  },
  linkStyle: {
    display: 'block',
    fontWeight: 'bold',
    color: '#020917',
    cursor: 'pointer',
    fontFamily: 'TT Commons',
    fontSize: 16,
    marginTop: 4
  },
  blinkingCursor: {
    marginLeft: 5,
    backgroundColor: '#BCC1CB',
    color: '#BCC1CB',
    animation: '$blink 0.4s infinite',
    fontSize: 11,
    borderStyle: 'solid',
    borderWidth: '4px'
  },
  '@keyframes blink': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  }
});

const ReadMore = ({
  classes, summaryData, streamStatus, isStreamingMode, responseOptionType, requestId, elementId
}) => (
  <div className={responseOptionType === constants.BULLET ? classes.readMoreWrapper : classes.readMoreWrapperForParagraph} id={requestId}>
    {isStreamingMode ? (
      <>
        <span dangerouslySetInnerHTML={{ __html: summaryData }} id={elementId} />
        {streamStatus === 'done' ? '' : <span className={classes.blinkingCursor}>|</span>}
      </>
    ) : <span dangerouslySetInnerHTML={{ __html: summaryData }} />}
  </div>
);

ReadMore.propTypes = {
  summaryData: PropTypes.string,
  classes: PropTypes.object.isRequired,
  streamStatus: PropTypes.string,
  elementId: PropTypes.string,
  isStreamingMode: PropTypes.bool,
  responseOptionType: PropTypes.string,
  requestId: PropTypes.string
};

ReadMore.defaultProps = {
  summaryData: '',
  streamStatus: '',
  elementId: '',
  isStreamingMode: false,
  responseOptionType: constants.BULLET,
  requestId: ''
};

export default withStyles(styles)(ReadMore);

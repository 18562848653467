/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file Footer.jsx
 * @author Sarathkumar Venkatachalam, Vignesh Selvam, Sai Kiran
 */

import React from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Box, Typography, Link } from '@material-ui/core';
import messages from '../../defaultMessages';
import ChatComponentStyles from './ChatComponentStyles';
import { eventAction, gaEvents } from '../../analytics/analytics';
import * as constants from '../../Common/constants';

const TAB = 'tab';
const Footer = ({
  classes, intl, isExpanded, handleGAEvents, isNewTopicEnabled, isSendButtonEnabled
}) => {
  const { formatMessage } = intl;
  const handleClick = () => {
    const additionalFields = {
      event_label: messages.footerLinkText.defaultMessage,
      event_value: null,
      event_action: eventAction.clickHereIsWhyLink
    };
    gaEvents(handleGAEvents, additionalFields);
  };
  const handleKeyDown = (event) => {
    const eventKey = event.key && event.key.toLowerCase();
    if (!isExpanded) {
      if (event.shiftKey && eventKey === TAB) {
        event.preventDefault();
        if (isNewTopicEnabled) {
          document.getElementById('clear').focus();
        } else if (isSendButtonEnabled) {
          document.getElementById('send').focus();
        } else {
          document.getElementById('inputTextBox').focus();
        }
      } else if (eventKey === TAB) {
        event.preventDefault();
        document.getElementById('expand_button').focus();
      }
    } else if (isExpanded && event.shiftKey && eventKey === TAB) {
      event.preventDefault();
      document.getElementById('inputTextBox').focus();
    } else if (isExpanded && eventKey === TAB) {
      event.preventDefault();
      if (isSendButtonEnabled) {
        document.getElementById('send').focus();
      } else if (isNewTopicEnabled) {
        document.getElementById('clear').focus();
      } else {
        document.getElementById('collapse_button').focus();
      }
    }
  };
  return (
    <Box className={classes.footerContainer}>
      <Typography variant="p" className={classes.footerText}>
        {formatMessage(messages.footerMessage)}
        {' '}
        {' '}
        <Link
          href={constants.footerLink}
          className={classes.linkText}
          target="_blank"
          onClick={handleClick}
          onKeyDown={(event) => handleKeyDown(event)}
          id="faqLink"
        >
          {formatMessage(messages.footerLinkText)}
        </Link>
        {constants.PERIOD}
      </Typography>
    </Box>
  );
};

Footer.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  handleGAEvents: PropTypes.func,
  isExpanded: PropTypes.bool,
  isSendButtonEnabled: PropTypes.bool,
  isNewTopicEnabled: PropTypes.bool
};
Footer.defaultProps = {
  isExpanded: false,
  handleGAEvents: () => {},
  isSendButtonEnabled: false,
  isNewTopicEnabled: false
};

export default withStyles(ChatComponentStyles)(injectIntl(Footer));

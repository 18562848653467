/*
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
/**
 * @author Sarathkumar Venkatachalam
 */

import SepiaSVG from './icons/RadioSepia.svg';
import DefaultSVG from './icons/RadioMaster.svg';
import CorrectSVG from './icons/correct.svg';
import WrongSVG from './icons/wrong.svg';
import DefaultRadio from './icons/defaultRadio.svg';

const StyleConstants = {
  plus: {
    default: {
      bgColor: '#F7F9FD',
      leftPanelbgColor: '#F7F9FD',
      borderColor: '1px solid #DDE3EE',
      textColor: '#020917',
      userMsgbgColor: '#B9EAFE',
      textboxBorder: '2px solid #C7CCD6',
      clearBorderColor: '1px solid #D2D7E2',
      textboxBgColor: '#FEFEFE',
      avatarBgColor: '#FEFEFE',
      botMsgColor: '#020917',
      optionsSubmitBtnBg: '#FEFEFE',
      userMessageScrollbar: {
        track: '#FFFFFF',
        thumb: '#959AA5',
        hover: '#646464'
      },
      chipBgColor: '#D2D7E2',
      placeHolderTextColor: '#60646D',
      radioBtnColor: '#989BA3',
      selectedRadioBtnColor: '#E7294C',
      formatColor: '#60646D',
      radioHoverColor: '#E8EEFA',
      radioSelectedBG: DefaultSVG,
      welcomeBtnsBgColor: '#E8EEFA',
      predefinedBtnsBgColor: '#E8EEFA',
      welcomeBtnsBgHoverColor: '#BCC1CB',
      welcomeBtnsBorderColor: '1px solid #E8EEFA',
      correctSelectedBG: CorrectSVG,
      wrongSelectedBG: WrongSVG,
      defaultRadio: DefaultRadio,
      searchIconColor: 'black',
      historyBgColor: '#FEFEFE',
      historySearchBgColor: '#FEFEFE',
      historySearchBorderColor: '2px solid #C7CCD6',
      snackBarBgColor: '#161C29',
      snackBarTextColor: '#FEFEFE',
      historyListBorder: '1px solid #DDE3EE',
      historyListBorderColor: '#DDE3EE',
      historyDrawerBgColor: '#F7F9FD',
      historyListTextHoverColor: '#4C406B',
      historyListActiveBgColor: 'rgba(67, 83, 110, 0.08)',
      historyListActiveBorderColor: '8px solid #43536E',
      messageIconColor: '#7487A7',
      historyRenameBorderColor: '2px solid #020917',
      historyCloseBgColor: '#60646D',
      historyListHoverColor: '#DDE3EE',
      historyListActiveTextColor: '#43536E',
      mathIconFillColor: '#655591',
      mathIconHoverColor: '#4C406B',
      mathIconActiveColor: '#FEFEFE',
      mathIconActiveBgColor: '#655591',
      mathIconBgColor: 'transparent',
      mathIconBgHoverColor: 'rgba(0, 0, 0, 0.04)',
      mathIconBgFocusColor: '#CCC',
      scrollTrackBgColor: '#F9F9F9',
      scrollThumbBgColor: 'rgba(0, 0, 0, 0.6)',
      faqColor: '#655591'
    },
    dark: {
      bgColor: '#101010',
      leftPanelbgColor: '#101010',
      borderColor: '1px solid #393F4A',
      textColor: '#FEFEFE',
      userMsgbgColor: '#29657A',
      textboxBgColor: '#1E2024',
      textboxBorder: '2px solid #60646D',
      clearBorderColor: '1px solid #212327',
      avatarBgColor: '#1E1E1E',
      botMsgColor: '#FEFEFE',
      optionsSubmitBtnBg: '#1E1E1E',
      userMessageScrollbar: {
        track: '#101010',
        thumb: '#959AA5',
        hover: '#FFFFFF'
      },
      chipBgColor: '#1E1E1E',
      placeHolderTextColor: '#8F8F8F',
      radioBtnColor: '#989BA3',
      selectedRadioBtnColor: '#E7294C',
      formatColor: '#989BA3',
      radioHoverColor: '#272D39',
      radioSelectedBG: DefaultSVG,
      welcomeBtnsBgColor: '#272D39',
      predefinedBtnsBgColor: '#343841',
      welcomeBtnsBgHoverColor: '#393F4A',
      welcomeBtnsBorderColor: '1px solid #393F4A',
      correctSelectedBG: CorrectSVG,
      wrongSelectedBG: WrongSVG,
      defaultRadio: DefaultRadio,
      searchIconColor: '#FEFEFE',
      historyBgColor: '#272D39',
      historySearchBgColor: '#1E2024',
      historySearchBorderColor: '2px solid #60646D',
      snackBarBgColor: '#F7F9FD',
      snackBarTextColor: '#020917',
      historyListBorder: '1px solid #DDE3EE',
      historyListBorderColor: '#DDE3EE',
      historyDrawerBgColor: '#101010',
      historyListTextHoverColor: '#FEFEFE',
      historyListActiveBgColor: 'rgba(83, 96, 121, 0.2)',
      historyListActiveBorderColor: '8px solid #536079',
      messageIconColor: '#FEFEFE',
      historyRenameBorderColor: '2px solid #FEFEFE',
      historyCloseBgColor: '#FEFEFE',
      historyListHoverColor: '#393F4A',
      historyListActiveTextColor: '#FEFEFE',
      mathIconFillColor: '#9B81E2',
      mathIconHoverColor: '#B79BFF',
      mathIconActiveColor: '#020917',
      mathIconActiveBgColor: ' #9B81E2',
      mathIconBgColor: '#FFFFFF',
      mathIconBgHoverColor: '#FFFFFF',
      mathIconBgFocusColor: '#FFFFFF',
      scrollTrackBgColor: 'rgba(0, 0, 0, 0.6)',
      scrollThumbBgColor: '#F9F9F9',
      faqColor: '#B79BFF'
    },
    sepia: {
      bgColor: '#F4F2E4',
      leftPanelbgColor: '#F4F2E4',
      borderColor: '1px solid #B1AB9B',
      textColor: '#101C33',
      userMsgbgColor: '#309CBD',
      textboxBgColor: '#FCFAED',
      textboxBorder: '2px solid #B1AB9B',
      clearBorderColor: '1px solid #BBBDC3',
      avatarBgColor: '#EBE2CD',
      botMsgColor: '#1D1B19',
      optionsSubmitBtnBg: '#F4F2E4',
      userMessageScrollbar: {
        track: '#f4f2e4',
        thumb: '#959AA5',
        hover: '#646464'
      },
      chipBgColor: '#F5F0E6',
      placeHolderTextColor: '#60646D',
      radioBtnColor: '#989BA3',
      selectedRadioBtnColor: '#4C406B',
      radioSelectedBG: SepiaSVG,
      welcomeBtnsBgColor: '#EBE2CD',
      predefinedBtnsBgColor: '#E9E3CF',
      welcomeBtnsBgHoverColor: '#B1AB9B',
      welcomeBtnsBorderColor: '1px solid #CCC',
      formatColor: '#60646D',
      radioHoverColor: '#CEC6B3',
      correctSelectedBG: CorrectSVG,
      wrongSelectedBG: WrongSVG,
      defaultRadio: DefaultRadio,
      searchIconColor: '#101C33',
      historyBgColor: '#EBE2CD',
      historySearchBgColor: '#FCFAED',
      historySearchBorderColor: '2px solid #B1AB9B',
      snackBarBgColor: '#161C29',
      snackBarTextColor: '#FEFEFE',
      historyListBorder: '1px solid #B1AB9B',
      historyListBorderColor: '#B1AB9B',
      historyDrawerBgColor: '#F4F2E4',
      historyListTextHoverColor: '#4C406B',
      historyListActiveBgColor: 'rgba(67, 83, 110, 0.08)',
      historyListActiveBorderColor: '8px solid #43536E',
      messageIconColor: '#61656D',
      historyRenameBorderColor: '2px solid #1D1B19',
      historyCloseBgColor: '#1D1B19',
      historyListHoverColor: '#B1AB9B',
      historyListActiveTextColor: '#43536E',
      mathIconFillColor: '#655591',
      mathIconHoverColor: '#4C406B',
      mathIconActiveColor: '#FEFEFE',
      mathIconActiveBgColor: '#655591',
      mathIconBgColor: 'transparent',
      mathIconBgHoverColor: 'rgba(0, 0, 0, 0.04)',
      mathIconBgFocusColor: '#CCC',
      scrollTrackBgColor: 'rgb(235, 226, 205)',
      scrollThumbBgColor: 'rgba(0, 0, 0, 0.6)',
      faqColor: '#A49738'
    }
  },
  standard: {
    default: {
      bgColor: '#FEFEFE',
      leftPanelbgColor: '#F7F9FD'
    },
    dark: {
      bgColor: '#161C29',
      leftPanelbgColor: '#101010'
    },
    sepia: {
      bgColor: '#EBE2CD',
      leftPanelbgColor: '#F4F2E4'
    }
  }
};

export default StyleConstants;

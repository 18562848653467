/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file BulletIcon.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import ThemeConstants from '../ThemeConstants';

const Styles = () => ({
  paragraphIcon: {
    width: 24,
    height: 24
  }
});
const ParagraphIcon = ({ classes, theme, masterTheme }) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 24 24"
    classes={{
      root: classes.paragraphIcon
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.50024 1.64404L6.4057 3.57569L8.4057 7.84236L8.9057 8.90902C9.1401 9.40909 8.92474 10.0045 8.42467 10.2389C7.9246 10.4733 7.32919 10.258 7.09478 9.75789L6.86457 9.26679H4.1359L3.9057 9.75789C3.67129 10.258 3.07587 10.4733 2.5758 10.2389C2.07573 10.0045 1.86037 9.40909 2.09478 8.90902L2.59478 7.84236L4.59478 3.57569L5.50024 1.64404ZM5.0734 7.26679H5.92707L5.50024 6.3562L5.0734 7.26679ZM11.0002 4.00012C11.0002 3.44784 11.448 3.00012 12.0002 3.00012H21.0002C21.5525 3.00012 22.0002 3.44784 22.0002 4.00012C22.0002 4.55241 21.5525 5.00012 21.0002 5.00012H12.0002C11.448 5.00012 11.0002 4.55241 11.0002 4.00012ZM11.0002 9.33346C11.0002 8.78117 11.448 8.33346 12.0002 8.33346H21.0002C21.5525 8.33346 22.0002 8.78117 22.0002 9.33346C22.0002 9.88574 21.5525 10.3335 21.0002 10.3335H12.0002C11.448 10.3335 11.0002 9.88574 11.0002 9.33346ZM2.00024 14.6668C2.00024 14.1145 2.44795 13.6668 3.00024 13.6668H21.0002C21.5525 13.6668 22.0002 14.1145 22.0002 14.6668C22.0002 15.2191 21.5525 15.6668 21.0002 15.6668H3.00024C2.44795 15.6668 2.00024 15.2191 2.00024 14.6668ZM2.00024 20.0001C2.00024 19.4478 2.44795 19.0001 3.00024 19.0001H21.0002C21.5525 19.0001 22.0002 19.4478 22.0002 20.0001C22.0002 20.5524 21.5525 21.0001 21.0002 21.0001H3.00024C2.44795 21.0001 2.00024 20.5524 2.00024 20.0001Z"
      fill={ThemeConstants[masterTheme][theme].textColor}
    />
  </SvgIcon>
);

ParagraphIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string,
  masterTheme: PropTypes.string
};

ParagraphIcon.defaultProps = {
  theme: 'default',
  masterTheme: 'plus'
};

export default withStyles(Styles)(ParagraphIcon);

/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file SuggestionScreen.jsx - A higher order component for suggested Feedback screens.
 * @author Arifhusain Soudagar
 */

import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const Styles = {
  screenContainer: {
    width: '100%',
    background: 'linear-gradient(116.96deg, rgba(5, 17, 42, 0.5) 25.6%, rgba(49, 1, 53, 0.5) 53.62%), linear-gradient(116.96deg, #310135 30.84%, #05112A 68.52%), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
    borderRadius: 14,
    padding: 24,
    position: 'absolute',
    boxSizing: 'border-box',
    bottom: 0,
    left: 0,
    zIndex: '9999 !important',
    border: '1px solid #FFFFFF'
  },
  screenContainerOverlay: {
    height: 'calc(100% - 92px)',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 100000
  },
  slideUp: {
    animation: '$bottomToTop 250ms ease',
    animationDirection: 'normal',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
    transform: 'translateY(40%)',
    transitionTimingFunction: 'cubic-bezier(0,0,0.2,1)'
  },
  '@keyframes bottomToTop': {
    to: {
      transform: 'translateY(0%)'
    }
  },
  slideDown: {
    animation: '$topToBottom 200ms ease',
    animationDirection: 'normal',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
    transform: 'translateY(0%)'
  },
  '@keyframes topToBottom': {
    to: {
      transform: 'translateY(100%)'
    }
  }
};

const SuggestionScreen = ({
  children, classes, isClose
}) => {
  const [animationClass, setAnimationClass] = useState(null);

  useEffect(() => {
    const screenContainerAnimationClass = isClose ? classes.slideDown : classes.slideUp;
    setAnimationClass(screenContainerAnimationClass);
    const animationClassTimeOut = isClose && setTimeout(() => {
      setAnimationClass(null);
    }, 200);
    return () => {
      clearTimeout(animationClassTimeOut);
    };
  }, [isClose]);

  return (
    <>
      <Box className={classes.screenContainerOverlay}>
        <Box className={`${classes.screenContainer} ${animationClass}`}>
          { children }
        </Box>
      </Box>
    </>
  );
};

SuggestionScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  isClose: PropTypes.bool
};

SuggestionScreen.defaultProps = {
  isClose: false
};

export default withStyles(Styles)(injectIntl(SuggestionScreen));

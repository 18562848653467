import React from 'react';
import {
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import * as constants from '../../Common/constants';
import ParagraphIcon from '../../Common/icons/ParagraphIcon';
import BulletIcon from '../../Common/icons/BulletIcon';
import FeedbackStyles from './FeedbackStyles';

const FormatChange = ({
  classes,
  optionSelected,
  onFormatChange,
  masterTheme,
  theme
}) => (
  <div className={classes.formatWrapper}>
    <Typography variant="p" className={classes.formatStyle}>Format to</Typography>
    <Tooltip
      classes={{ tooltip: classes.iconsTooltip, arrow: classes.tooltipArrow }}
      title={optionSelected === constants.BULLET ? 'Paragraph' : 'Bullet points'}
      placement="top"
      arrow
    >
      <IconButton
        className={classes.feedbackButton}
        onClick={onFormatChange}
      >
        {
            optionSelected === constants.BULLET ? <ParagraphIcon masterTheme={masterTheme} theme={theme} /> : <BulletIcon masterTheme={masterTheme} theme={theme} />
        }
      </IconButton>
    </Tooltip>
  </div>
);

FormatChange.propTypes = {
  classes: PropTypes.object.isRequired,
  optionSelected: PropTypes.string,
  theme: PropTypes.string.isRequired,
  masterTheme: PropTypes.string.isRequired,
  onFormatChange: PropTypes.func
};

FormatChange.defaultProps = {
  optionSelected: '',
  onFormatChange: () => {}
};

export default withStyles(FeedbackStyles)(injectIntl(FormatChange));

/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file TutorEyes.jsx
 * @author Arifhusain Soudagar
 */

import React from 'react';

const TutorEyes = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="46" height="42" viewBox="0 0 46 42" fill="none" className="tutorEyes-active-inactive">
    <g filter="url(#filter0_d_5544_79743)">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.1613 14C11.9035 14 11.6946 14.2089 11.6946 14.4667C11.6946 14.7244 11.9035 14.9333 12.1613 14.9333H15.988C16.2457 14.9333 16.4546 14.7244 16.4546 14.4667C16.4546 14.2089 16.2457 14 15.988 14H12.1613ZM10.2475 15.8671C9.73209 15.8671 9.31423 16.2849 9.31423 16.8004C9.31423 17.3158 9.73209 17.7337 10.2475 17.7337H17.9009C18.4164 17.7337 18.8342 17.3158 18.8342 16.8004C18.8342 16.2849 18.4164 15.8671 17.9009 15.8671H10.2475ZM8.125 19.5998C8.125 19.0844 8.54286 18.6665 9.05832 18.6665H19.0917C19.6071 18.6665 20.025 19.0844 20.025 19.5998C20.025 20.1153 19.6071 20.5331 19.0917 20.5331H9.05832C8.54286 20.5331 8.125 20.1153 8.125 19.5998ZM9.05832 21.4666C8.54286 21.4666 8.125 21.8844 8.125 22.3999C8.125 22.9153 8.54286 23.3332 9.05832 23.3332H19.0917C19.6071 23.3332 20.025 22.9153 20.025 22.3999C20.025 21.8844 19.6071 21.4666 19.0917 21.4666H9.05832ZM9.31423 25.2001C9.31423 24.6846 9.73209 24.2667 10.2475 24.2667H17.9009C18.4164 24.2667 18.8342 24.6846 18.8342 25.2001C18.8342 25.7155 18.4164 26.1334 17.9009 26.1334H10.2475C9.73209 26.1334 9.31423 25.7155 9.31423 25.2001ZM12.1613 27.0669C11.9035 27.0669 11.6946 27.2758 11.6946 27.5336C11.6946 27.7913 11.9035 28.0002 12.1613 28.0002H15.988C16.2457 28.0002 16.4546 27.7913 16.4546 27.5336C16.4546 27.2758 16.2457 27.0669 15.988 27.0669H12.1613Z" fill="#62FFEE" />
    </g>
    <g filter="url(#filter1_d_5544_79743)">
      <path fillRule="evenodd" clipRule="evenodd" d="M30.0113 14C29.7535 14 29.5446 14.2089 29.5446 14.4667C29.5446 14.7244 29.7535 14.9333 30.0113 14.9333H33.838C34.0957 14.9333 34.3046 14.7244 34.3046 14.4667C34.3046 14.2089 34.0957 14 33.838 14H30.0113ZM28.0975 15.8671C27.5821 15.8671 27.1642 16.2849 27.1642 16.8004C27.1642 17.3158 27.5821 17.7337 28.0975 17.7337H35.7509C36.2664 17.7337 36.6842 17.3158 36.6842 16.8004C36.6842 16.2849 36.2664 15.8671 35.7509 15.8671H28.0975ZM25.975 19.5998C25.975 19.0844 26.3929 18.6665 26.9083 18.6665H36.9417C37.4571 18.6665 37.875 19.0844 37.875 19.5998C37.875 20.1153 37.4571 20.5331 36.9417 20.5331H26.9083C26.3929 20.5331 25.975 20.1153 25.975 19.5998ZM26.9083 21.4666C26.3929 21.4666 25.975 21.8844 25.975 22.3999C25.975 22.9153 26.3929 23.3332 26.9083 23.3332H36.9417C37.4571 23.3332 37.875 22.9153 37.875 22.3999C37.875 21.8844 37.4571 21.4666 36.9417 21.4666H26.9083ZM27.1642 25.2001C27.1642 24.6846 27.5821 24.2667 28.0975 24.2667H35.7509C36.2664 24.2667 36.6842 24.6846 36.6842 25.2001C36.6842 25.7155 36.2664 26.1334 35.7509 26.1334H28.0975C27.5821 26.1334 27.1642 25.7155 27.1642 25.2001ZM30.0113 27.0669C29.7535 27.0669 29.5446 27.2758 29.5446 27.5336C29.5446 27.7913 29.7535 28.0002 30.0113 28.0002H33.838C34.0957 28.0002 34.3046 27.7913 34.3046 27.5336C34.3046 27.2758 34.0957 27.0669 33.838 27.0669H30.0113Z" fill="#62FFEE" />
    </g>
    <defs>
      <filter id="filter0_d_5544_79743" x="0.125" y="6" width="27.9" height="30.0002" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.384314 0 0 0 0 1 0 0 0 0 0.933333 0 0 0 0.45 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5544_79743" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5544_79743" result="shape" />
      </filter>
      <filter id="filter1_d_5544_79743" x="17.975" y="6" width="27.9" height="30.0002" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.384314 0 0 0 0 1 0 0 0 0 0.933333 0 0 0 0.45 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5544_79743" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5544_79743" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default TutorEyes;

import React, { useState } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import messages from '../../defaultMessages';
import FeedbackStyles from './FeedbackStyles';

const FeedbackSubmitMessage = ({
  classes,
  intl,
  handleCloseFeedbackMessagePopup,
  isClickAway
}) => {
  const { formatMessage } = intl;
  const [isClose, setIsClose] = useState(false);
  const handleClose = () => {
    setIsClose(true);
    setTimeout(() => {
      handleCloseFeedbackMessagePopup();
    }, 200);
  };
  return (
    <Box className={`${classes.feedbackContainer} ${classes.feedbackSubmitHeading} ${isClose || isClickAway ? classes.slideDown : classes.thankyouSlideUp}`} aria-labelledby="feedbacksubmitMessage">
      <Box className={classes.feedbackContainer__Header} id="feedbacksubmitMessageTitle">
        <Typography id="feedbacksubmitMessage" className={`${classes.feedbackContainer__Header__text} ${classes.alignMessageCenter}`} variant="h4">{formatMessage(messages.feedbacksubmitMessage)}</Typography>
      </Box>
      <div className={classes.feedbackActionWrapper} style={{ marginTop: 16 }}>
        <Button
          autoFocus
          id="summary__PositiveFeedback__submit"
        //   classes={{ disabled: classes.sendButtonDisabled }}
          className={classes.sendButton}
          onClick={handleClose}
        >
          {formatMessage(messages.dismiss)}
        </Button>
      </div>
    </Box>
  );
};

FeedbackSubmitMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  handleCloseFeedbackMessagePopup: PropTypes.func,
  isClickAway: PropTypes.bool.isRequired
};

FeedbackSubmitMessage.defaultProps = {
  handleCloseFeedbackMessagePopup: () => { }
};

export default withStyles(FeedbackStyles)(injectIntl(FeedbackSubmitMessage));

import { LIVE, PROMPT_VERSION_KEYWORDS } from './constants';
/**
 * Extracts prompt version data from the response data.
 *
 * @param {Object} responseData - The response data containing prompts.
 * @param {Object} constants - The constants object containing prompt version keywords and live status.
 * @returns {Object|null} The prompt version data or null if not found.
 */

const getPromptVersionData = (responseData) => {
  const liveStatus = LIVE;

  // Define the prompt version data extractors
  const extractors = [
    {
      name: PROMPT_VERSION_KEYWORDS.QUESTIONS_FROM_TOPICS,
      prefix: PROMPT_VERSION_KEYWORDS.QUESTIONS_TOPICS
    },
    {
      name: PROMPT_VERSION_KEYWORDS.CLICK_TOPIC_SUGGESTION,
      prefix: PROMPT_VERSION_KEYWORDS.TOPIC_USER_SELECTED
    }
  ];

  // Find the first matching prompt version data
  const promptVersionData = extractors.find((extractor) => {
    const data = responseData?.prompts?.find((item) => item.name === extractor.name && item.status === liveStatus);
    // eslint-disable-next-line no-param-reassign
    if (data && data.name && data.version) extractor.version = data.version;
    return data && data.name && data.version;
  });

  // Return the prompt version data if found
  if (promptVersionData) {
    const extractor = extractors.find((item) => item.name === promptVersionData.name);
    return { prompt_version: `${extractor.prefix}_${promptVersionData.version}` };
  }

  return null;
};

export default getPromptVersionData;

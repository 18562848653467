/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file ThreeDots.jsx
 * @author Arifhusain Soudagar
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import ThemeConstants from '../ThemeConstants';

const Styles = () => ({
  threeDots: {
    width: 16,
    height: 16
  }
});

const ThreeDots = ({ classes, masterTheme, theme }) => (
  <SvgIcon className={classes.threeDots} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 11.45C8.69036 11.45 9.25 12.0096 9.25 12.7C9.25 13.3904 8.69036 13.95 8 13.95C7.30964 13.95 6.75 13.3904 6.75 12.7C6.75 12.0096 7.30964 11.45 8 11.45ZM8 6.75C8.69036 6.75 9.25 7.30964 9.25 8C9.25 8.69036 8.69036 9.25 8 9.25C7.30964 9.25 6.75 8.69036 6.75 8C6.75 7.30964 7.30964 6.75 8 6.75ZM8 2C8.69036 2 9.25 2.55964 9.25 3.25C9.25 3.94036 8.69036 4.5 8 4.5C7.30964 4.5 6.75 3.94036 6.75 3.25C6.75 2.55964 7.30964 2 8 2Z" fill={ThemeConstants[masterTheme][theme].textColor} />
  </SvgIcon>
);

ThreeDots.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string,
  masterTheme: PropTypes.string
};

ThreeDots.defaultProps = {
  theme: 'default',
  masterTheme: 'plus'
};

export default withStyles(Styles)(ThreeDots);

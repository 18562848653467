/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file ChatHistory.jsx
 * @author Sarathkumar Venkatachalam
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Divider } from '@material-ui/core';
import ChatHistoryStyles from './ChatHistoryStyles';
import ChatHistoryHeader from './ChatHistoryHeader';
import ChatHistorySearch from './ChatHistorySearch';
import ChatHistoryList from './ChatHistoryList';
import LoaderIcon from '../../Common/icons/LoaderIcon';
import * as constants from '../../Common/constants';
import messages from '../../defaultMessages';
import Utils from '../../Common/Utils';
import NoSearchResultIcon from '../../Common/icons/NoSearchResultIcon';
import { inputTextForChatHistoryFilterGAEvent, openThreadFromChatHistoryGAEvent } from '../../analytics/analyticsEvents';
import EmptyState from './EmptyState';

const useDebounce = (callback, delay) => {
  let timer = null;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

const loadDebounce = useDebounce(inputTextForChatHistoryFilterGAEvent, constants.FILTER_SEARCH_DELAY_GA);

const ChatHistory = ({
  masterTheme,
  theme,
  intl,
  onClose,
  onRenderHistory,
  onDeleteTitle,
  classes,
  getBerlinClientAPI,
  bookId,
  handleGAEvents,
  threadDeleteTimeOut
}) => {
  const { formatMessage } = intl;
  const [historyFetched, setHistoryFetched] = useState(false);
  const [titleTotal, setTitleTotal] = useState(0);
  const [titleOffset, setTitleOffset] = useState(0);
  const [filteredHistoryData, setFilteredHistoryData] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [scrollDivider, setScrollDivider] = useState(false);

  const getUserHistoryTitle = (historyTitlePayload, fromSearch) => {
    getBerlinClientAPI().getUserChatHistory(historyTitlePayload, (responseData) => {
      if (responseData) {
        const {
          offset,
          total,
          response
        } = responseData;
        setHistoryFetched(true);
        loadDebounce(handleGAEvents, bookId, historyTitlePayload.search, total);
        setTitleOffset(offset);
        setTitleTotal(total);
        const structuredTitleData = fromSearch ? response : filteredHistoryData.concat(response);
        const groupedTitle = Utils.groupHistoryTitle(structuredTitleData);
        setFilteredHistoryData(structuredTitleData);
        setGroupedData(groupedTitle);
      }
    });
  };

  useEffect(() => {
    const titlePayload = {
      limit: constants.CHAT_HISTORY_TITLE_LIMIT,
      offset: 0,
      bookId
    };
    getUserHistoryTitle(titlePayload);
  }, []);

  const handleHistorySearch = (searchValue) => {
    const trimSearchValue = searchValue && searchValue.trim();
    const searchKey = trimSearchValue ? searchValue : '';
    setSearchKeyword(searchKey);
    if (!searchKey || (searchKey && searchKey.length >= constants.CHAT_HISTORY_SEARCH_CHAR_LIMIT)) {
      const searchPayload = {
        limit: constants.CHAT_HISTORY_TITLE_LIMIT,
        offset: 0,
        bookId
      };
      if (searchKey && searchKey.length >= constants.CHAT_HISTORY_SEARCH_CHAR_LIMIT) {
        searchPayload.search = searchKey;
      }
      getUserHistoryTitle(searchPayload, true);
    }
  };
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const reachedBottom = Math.ceil(scrollTop) >= Math.ceil(scrollHeight - clientHeight);
    setScrollDivider(scrollTop > 0);
    /** User History Title pagination Start */
    if (reachedBottom && titleTotal > (titleOffset + constants.CHAT_HISTORY_TITLE_LIMIT)) {
      const titlePayload = {
        limit: constants.CHAT_HISTORY_TITLE_LIMIT,
        offset: titleOffset + constants.CHAT_HISTORY_TITLE_LIMIT,
        bookId
      };
      if (searchKeyword && searchKeyword.length >= constants.CHAT_HISTORY_SEARCH_CHAR_LIMIT) {
        titlePayload.search = searchKeyword;
      }
      getUserHistoryTitle(titlePayload);
    }
    /** User History Title pagination Start */
  };
  const handleRenderHistory = (threadId) => {
    if (filteredHistoryData && filteredHistoryData.length) {
      const currentThreadData = filteredHistoryData.filter((item) => item.threadId === threadId);
      const threadUpdatedDay = Utils.getHistoryFormat(currentThreadData && currentThreadData.length && currentThreadData[0] && currentThreadData[0].updatedOn);
      openThreadFromChatHistoryGAEvent(handleGAEvents, threadUpdatedDay, bookId, threadId);
    }
    onRenderHistory(threadId);
  };

  const getHistoryComponent = () => {
    let component = '';
    if (historyFetched) {
      if (searchKeyword && !(filteredHistoryData && filteredHistoryData.length)) {
        component = (
          <>
            <ChatHistorySearch
              onSearch={handleHistorySearch}
              masterTheme={masterTheme}
              theme={theme}
            />
            <div className={classes.noSearchResultWrapper}>
              <div className={classes.noSearchIconStyle}>
                <NoSearchResultIcon />
              </div>
              <Typography variant="body1" className={classes.noSearchResultStyle}>
                {formatMessage(messages.noSearchResult)}
                <span className={classes.fbold}>{`'${searchKeyword}'`}</span>
              </Typography>
            </div>
          </>
        );
      } else if (filteredHistoryData && filteredHistoryData.length) {
        component = (
          <>
            <ChatHistorySearch
              onSearch={handleHistorySearch}
              masterTheme={masterTheme}
              theme={theme}
            />
            <div
              id="user-history-list"
              className={scrollDivider ? `${classes.historyScrollBar} ${classes.scrollDivider}` : classes.historyScrollBar}
              onScroll={handleScroll}
            >
              {
                groupedData.length > 0 && groupedData.map((historyObj) => historyObj && historyObj.list && historyObj.list.length > 0 && (
                  <>
                    <ChatHistoryList
                      historyData={historyObj}
                      onRenderHistory={handleRenderHistory}
                      threadDeleteTimeOut={threadDeleteTimeOut}
                      onDeleteTitle={onDeleteTitle}
                      bookId={bookId}
                      handleGAEvents={handleGAEvents}
                      getBerlinClientAPI={getBerlinClientAPI}
                      masterTheme={masterTheme}
                      theme={theme}
                    />
                    <Divider
                      classes={{
                        root: classes.groupDividerStyle
                      }}
                    />
                  </>
                ))
              }
            </div>
          </>
        );
      } else {
        component = (
          <>
            <ChatHistorySearch
              onSearch={handleHistorySearch}
              masterTheme={masterTheme}
              theme={theme}
              disabled
            />
            <EmptyState masterTheme={masterTheme} theme={theme} />
          </>
        );
      }
    } else {
      component = (
        <div className={classes.historyLoader}>
          <LoaderIcon />
        </div>
      );
    }
    return component;
  };

  return (
    <>
      <ChatHistoryHeader
        onClose={onClose}
        masterTheme={masterTheme}
        theme={theme}
      />
      {
        getHistoryComponent()
      }
    </>
  );
};

ChatHistory.propTypes = {
  masterTheme: PropTypes.string,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string,
  intl: intlShape.isRequired,
  onClose: PropTypes.func.isRequired,
  onRenderHistory: PropTypes.func.isRequired,
  onDeleteTitle: PropTypes.func.isRequired,
  getBerlinClientAPI: PropTypes.func.isRequired,
  handleGAEvents: PropTypes.func.isRequired,
  bookId: PropTypes.string.isRequired,
  threadDeleteTimeOut: PropTypes.number.isRequired
};

ChatHistory.defaultProps = {
  masterTheme: 'plus',
  theme: 'default'
};

export default withStyles(ChatHistoryStyles)(injectIntl(ChatHistory));

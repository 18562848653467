/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file SingleTopic.jsx
 * @author Arifhusain Soudagar
 */

import React from 'react';
import { intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import messages from '../../defaultMessages';
import ThemeConstants from '../../Common/ThemeConstants';

const styles = () => ({
  topicStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    lineHeight: '24px'
  },
  topicBottomStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    paddingTop: 18,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    letterSpacing: '0.24px',
    lineHeight: '24px'
  },
  topic: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    paddingLeft: 30,
    marginBottom: 0,
    fontWeight: 'bold',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  }
});

const SingleTopic = ({
  classes, topic, intl, isContentSelectorFirstTime
}) => {
  const { formatMessage } = intl;

  return (
    <>
      <Typography className={classes.topicStyle}>{isContentSelectorFirstTime ? formatMessage(messages.singleTopicMessageForFirstTimeUser) : formatMessage(messages.singleTopicMessageForSecondTimeUser)}</Typography>
      <h2 className={classes.topic}>{topic}</h2>
      {/* <Typography className={classes.topicBottomStyle}>{formatMessage(messages.moreOnSingleTopic)}</Typography> */}
    </>
  );
};

SingleTopic.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  isContentSelectorFirstTime: PropTypes.bool.isRequired,
  topic: PropTypes.string
};

SingleTopic.defaultProps = {
  topic: ''
};

export default withStyles(styles)(injectIntl(SingleTopic));

/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file RightIcon.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import ThemeConstants from '../ThemeConstants';

const Styles = () => ({
  rightIcon: {
    width: 18,
    height: 18
  }
});

const RightIcon = ({ classes, theme, masterTheme }) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 18 18"
    classes={{
      root: classes.rightIcon
    }}
  >
    <path d="M6.32881 12.5165L3.20441 9.00155C2.95674 8.72293 2.53009 8.69783 2.25146 8.9455C1.97284 9.19317 1.94774 9.61982 2.19541 9.89845L5.79541 13.9484C6.05343 14.2387 6.50258 14.2519 6.77721 13.9773L16.2272 4.5273C16.4908 4.26369 16.4908 3.83631 16.2272 3.5727C15.9636 3.3091 15.5362 3.3091 15.2726 3.5727L6.32881 12.5165Z" fill={ThemeConstants[masterTheme][theme].placeHolderTextColor} />
  </SvgIcon>
);

RightIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string,
  masterTheme: PropTypes.string
};

RightIcon.defaultProps = {
  theme: 'default',
  masterTheme: 'plus'
};

export default withStyles(Styles)(RightIcon);

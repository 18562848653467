/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file CheckboxIcon.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';

const Styles = () => ({
  checkedIcon: {
    width: 20,
    height: 20,
    fill: 'none !important'
  }
});

const CheckboxIcon = ({ classes }) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 20 20"
    classes={{
      root: classes.checkedIcon
    }}
  >
    <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#989BA3" />
  </SvgIcon>
);

CheckboxIcon.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(Styles)(CheckboxIcon);

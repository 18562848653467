/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Translation index file
 *
 * @file index.js
 */

import arJson from './ar.json';
import deJson from './de.json';
import enJson from './en.json';
import esJson from './es.json';
import esCoJson from './es-co.json';
import esMxJson from './es-mx.json';
import frCaJson from './fr-ca.json';
import frJson from './fr.json';
import idJson from './id.json';
import itJson from './it.json';
import jaJson from './ja.json';
import koJson from './ko.json';
import nlJson from './nl.json';
import plJson from './pl.json';
import pseudoJson from './pseudo.json';
import ptBrJson from './pt-br.json';
import ruJson from './ru.json';
import trJson from './tr.json';
import zhCnJson from './zh-cn.json';
import zhHkJson from './zh-hk.json';

/* eslint-disable quote-props */
export default {
  translations: {
    'ar': arJson,
    'de': deJson,
    'de-de': deJson,
    'en': enJson,
    'en-us': enJson,
    'es': esJson,
    'es-co': esCoJson,
    'es-mx': esMxJson,
    'es-la': esCoJson,
    'fr': frJson,
    'fr-ca': frCaJson,
    'fr-fr': frJson,
    'id': idJson,
    'it': itJson,
    'it-it': itJson,
    'ja': jaJson,
    'ko': koJson,
    'nl': nlJson,
    'nl-nl': nlJson,
    'pl': plJson,
    'pt-br': ptBrJson,
    'ru': ruJson,
    'ts': pseudoJson,
    'tr': trJson,
    'tr-tr': trJson,
    'zh-cn': zhCnJson,
    'zh-hk': zhHkJson
  }
};

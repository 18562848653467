/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file DeleteBinIcon.jsx
 * @author Arifhusain Soudagar
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import ThemeConstants from '../ThemeConstants';

const Styles = () => ({
  deleteBinIcon: {
    width: 16,
    height: 16
  }
});

const DeleteBinIcon = ({ classes, masterTheme, theme }) => (
  <SvgIcon className={classes.deleteBinIcon} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.5778 0C9.48601 0 10.2222 0.736243 10.2222 1.64444L10.2169 1.77778H14.3334C14.7629 1.77778 15.1111 2.126 15.1111 2.55556C15.1111 2.98511 14.7629 3.33333 14.3334 3.33333H14.2222V13.7778C14.2222 15.0051 13.2273 16 12 16H4.00003C2.77273 16 1.7778 15.0051 1.7778 13.7778V3.33333H1.66669C1.23714 3.33333 0.888916 2.98511 0.888916 2.55556C0.888916 2.126 1.23714 1.77778 1.66669 1.77778H5.78313C5.7796 1.7338 5.7778 1.68933 5.7778 1.64444C5.7778 0.736243 6.51405 0 7.42225 0H8.5778ZM12.6667 3.33333H3.33336V13.7778C3.33336 14.146 3.63184 14.4444 4.00003 14.4444H12C12.3682 14.4444 12.6667 14.146 12.6667 13.7778V3.33333ZM6.22225 5.33333V12C6.22225 12.4909 5.82428 12.8889 5.33336 12.8889C4.84244 12.8889 4.44447 12.4909 4.44447 12V5.33333H6.22225ZM8.88892 5.33333V12C8.88892 12.4909 8.49095 12.8889 8.00003 12.8889C7.50911 12.8889 7.11114 12.4909 7.11114 12V5.33333H8.88892ZM11.5556 5.33333V12C11.5556 12.4909 11.1576 12.8889 10.6667 12.8889C10.1758 12.8889 9.77781 12.4909 9.77781 12V5.33333H11.5556Z" fill={ThemeConstants[masterTheme][theme].textColor} />
  </SvgIcon>
);

DeleteBinIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string,
  masterTheme: PropTypes.string
};

DeleteBinIcon.defaultProps = {
  theme: 'default',
  masterTheme: 'plus'
};

export default withStyles(Styles)(DeleteBinIcon);

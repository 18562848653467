/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import utf8 from 'utf8';
import { encode } from 'base-64';
import * as constants from './constants';

/**
 * Common Application utilities
 *
 * @file Utils.js
 * @author Vignesh Selvam
 */

const QUIZ_OPTIONS = [...Array(26)].map((_, i) => String.fromCharCode(i + 97));
const YES = 'yes';
const PROBLEM_SOLVE_RECTIFIER_LOADER_MESSAGE = 'Actually, something about this doesn\'t seem right. Hold on while I check my work.';
const isSafari = () => {
  const userAgent = window.navigator.userAgent;
  const isSafariBrowser = userAgent && (userAgent.toLowerCase().indexOf('safari/') > -1) && !(userAgent.toLowerCase().indexOf('chrome/') > -1);
  return isSafariBrowser;
};

const exportSafariCopyText = (copyText) => {
  const contentType = 'text/html';
  const blobInput = new window.Blob([copyText], { type: contentType });
  const blobInputClipboardItems = { 'text/html': blobInput };
  const clipboardItemInput = new window.ClipboardItem(blobInputClipboardItems);
  if (window.navigator.clipboard) {
    window.navigator.clipboard.write([clipboardItemInput]);
  }
};
export default class Utils {
    static scrollToView = (delay = 0, idName, addStyle = false, bottom = false) => {
      setTimeout(() => {
        const element = document.getElementById(idName || 'message_block');
        if (element && !addStyle) {
          if (bottom) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
          } else {
            element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          }
        }
      }, delay);
    }

    // static createUUID = () => {
    //   let dt = new Date().getTime();
    //   const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    //     const r = (dt + Math.random() * 16) % 16 | 0;
    //     dt = Math.floor(dt / 16);
    //     return (c === 'x' ? r : (r & 0x3 || 0x8)).toString(16);
    //   });
    //   return uuid;
    // }

  static concatChapterTitle = (chapterList) => {
    let chapterTitle = '';
    // eslint-disable-next-line no-return-assign
    chapterList.forEach((chapter, index) => {
      if (chapterList.length === 1) {
        chapterTitle += ` ${chapter}`;
      } else if (chapterList.length === index + 1) {
        chapterTitle += `and ${chapter}`;
      } else {
        chapterTitle += `${chapter}, `;
      }
    });
    return chapterTitle;
  }

  static getErrorMessage = (errorCode) => {
    let errorMessage = 'I\'m overheating, come back later';
    if (errorCode && errorCode.toString().charAt(0) === '4') {
      errorMessage = 'Whoops, something went wrong. This can happen sometimes, since I\'m powered by AI. For now, try a different request.';
    } else if (errorCode && errorCode.toString().charAt(0) === '5') {
      errorMessage = errorCode === 504 ? 'Sorry, I\'m at capacity right now. Try again in a few minutes.' : 'Sorry, I\'m having a hard time with your request at the moment. Try again later.';
    } else if (errorCode && errorCode.toString() === 'ECONNABORTED') {
      errorMessage = 'Hmm. I\'m not quite understanding your request. Try rephrasing it.';
    } else if (!errorCode) {
      errorMessage = 'Sorry, I\'m having a hard time with your request at the moment. Try again later.';
    }

    return errorMessage;
  }

  static getDeepSummaryFromBulletPoints = (bulletPoints) => {
    const deepSummaryData = [];
    let globalIndex = 0;
    bulletPoints.forEach((points, index) => {
      if (points.includes('<h2>')) {
        deepSummaryData[index] = {};
        deepSummaryData[index].bulletPoints = [];
        deepSummaryData[index].title = points.replace('<h2>', '').replace('</h2>', '');
        globalIndex = index;
      } else {
        if (globalIndex === 0 && !deepSummaryData.length) {
          deepSummaryData[globalIndex] = {};
          deepSummaryData[globalIndex].bulletPoints = [];
          deepSummaryData[globalIndex].title = '';
        }
        deepSummaryData[globalIndex].bulletPoints.push(points);
      }
    });
    return deepSummaryData;
  }

  static convertNumberToWords =(number) => {
    const num = 'zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen'.split(' ');
    const tens = 'twenty thirty forty fifty sixty seventy eighty ninety'.split(' ');
    if (number < 20) {
      return num[number];
    }
    const digit = number % 10;
    if (number < 100) {
      return tens[Math.floor(number / 10) - 2] + (digit ? `-${num[digit]}` : '');
    }
    if (number < 1000) {
      return `${num[Math.floor(number / 100)]} hundred${number % 100 === 0 ? '' : ` ${this.convertNumberToWords(number % 100)}`}`;
    }
    return `${this.convertNumberToWords(Math.floor(number / 1000))} thousand${number % 1000 !== 0 ? ` ${this.convertNumberToWords(number % 1000)}` : ''}`;
  }

  static replaceBullets = (data) => {
    const regex = /^(•)/;
    return data ? data.replace(regex, '<li>') : '';
  }

  static quizCopyTemplate = (type, question, options, userAnswer, feedback, selectedAnswerIndexId) => {
    let quizOptionList = '';
    let correctAnswer = '';
    let selectedAnswer = '';
    if (type === constants.MULTIPLE_CHOICE && options.length) {
      options.forEach((option, index) => {
        quizOptionList += `<div><strong>${QUIZ_OPTIONS[index]})</strong> ${option.distractor}</div>`;
        if (option.correct_choice && option.correct_choice.toLowerCase() === YES) {
          correctAnswer = `${QUIZ_OPTIONS[index]}) ${option.distractor}`;
        }
        if (option.id === selectedAnswerIndexId) {
          selectedAnswer = `${QUIZ_OPTIONS[index]}) ${option.distractor}`;
        }
      });
    }
    const copyTemplate = `<div class="copyTextWrapper"><div><strong>${question}</strong></div><br />${type === constants.MULTIPLE_CHOICE ? (`<div><div>${quizOptionList}</div><br /><div><strong>Correct answer: </strong>${correctAnswer}</div></div>`) : ''}<div><strong>Your answer: </strong>${selectedAnswer || userAnswer}</div><br /><div><i>Feedback:</i></div><div>${feedback}</div></div>`;
    return copyTemplate;
  }

  static base64Encode = (data) => encode(utf8.encode(JSON.stringify(data)))

  static exportCopyText = (copyText) => {
    if (isSafari()) {
      exportSafariCopyText(copyText);
    } else {
      const listener = (event) => {
        event.clipboardData.setData('text/html', copyText);
        event.clipboardData.setData('text/plain', copyText);
        event.preventDefault();
      };
      document.addEventListener('copy', listener);
      document.execCommand('copy');
      document.removeEventListener('copy', listener);
    }
  }

  static convertToMarkedDownString = (content, AIEnableMarkdown) => {
    try {
      if (AIEnableMarkdown) {
        const checkCharExist = typeof content === 'string' && content && content.trim();
        if (checkCharExist && checkCharExist.length > 0) {
          // eslint-disable-next-line no-undef
          return marked.parse(content);
          // eslint-disable-next-line no-undef
          /* const parsedData = marked.parse(content.replace(/ {4}/g, '%%%%').replaceAll('`', '@@').replaceAll('\\', '@!'));
          return parsedData.replaceAll('%%%%', '    ').replaceAll('@@', '`').replaceAll('@!', '\\').replaceAll('<em>', '_')
            .replaceAll('</em>', '_'); */
        }
        return checkCharExist;
      }
      return content;
    } catch (e) {
      console.log('error in converting markdown');
      return content;
    }
  }

  static stepsMapper = (historyArray, fetchChapterData) => {
    const stepsArray = [];
    let userMessageStep = { };
    let responseMessageStep = { };
    let chapterData = {
      chapterId: '',
      chapterTitle: '',
      chapterNumber: ''
    };
    if (historyArray && historyArray.length) {
      historyArray.forEach((historyObject) => {
        const {
          requestIds,
          responseId,
          pageIds,
          aiResponse,
          bookId,
          userInput,
          category
        } = historyObject || {};
        userMessageStep = {
          id: constants.USER_MESSAGE,
          data: {
            text: userInput
          }
        };
        const historyResponse = aiResponse && aiResponse.length && aiResponse[0];
        const historyQuestion = historyResponse && historyResponse.questions;
        const quizOption = (historyResponse && historyResponse.option) || constants.MULTIPLE_CHOICE;
        const quizQuestionData = (historyQuestion && historyQuestion instanceof Array) ? ((historyQuestion.length && historyQuestion[0]) || {}) : historyQuestion; // TODO Need to revisit once API changes done
        const requestId = requestIds && requestIds.length && requestIds[0];
        if (pageIds && pageIds.length) {
          const currentSectionData = fetchChapterData(historyObject.pageIds[0]);
          chapterData = currentSectionData && currentSectionData.chapterData;
        }
        switch (historyObject.intentType) {
          case constants.EXPLAIN_INTENT:
            responseMessageStep = {
              id: constants.STREAMING_DISCUSS,
              requestId,
              responseId,
              content: historyResponse,
              apiContent: historyResponse,
              streamStatus: constants.DONE,
              fromHistory: true,
              data: {
                bookID: bookId,
                chapterData
              }
            };
            if (userInput) {
              stepsArray.push(userMessageStep, responseMessageStep);
            } else {
              stepsArray.push(responseMessageStep);
            }
            break;
          case constants.PROBLEM_SOLVE_FEATURE:
            responseMessageStep = {
              id: constants.STREAMING_DISCUSS,
              requestId,
              responseId,
              content: historyResponse,
              apiContent: historyResponse,
              streamStatus: constants.DONE,
              fromHistory: true,
              payloadType: constants.PROBLEM_SOLVE_FEATURE,
              data: {
                bookID: bookId,
                chapterData
              }
            };
            if (aiResponse && aiResponse.length && aiResponse.length > 1) {
              if (category === constants.CALCULATION) { // Problem solve calculation
                stepsArray.push(
                  userMessageStep,
                  { ...responseMessageStep, category },
                  {
                    ...responseMessageStep,
                    payloadType: constants.PROBLEM_SOLVE_CALCULATION,
                    content: aiResponse[1],
                    apiContent: `${aiResponse[0]} ${aiResponse[1]}`
                  }
                );
              } else { // Problem solve rectifier
                stepsArray.push(
                  userMessageStep,
                  { ...responseMessageStep, useRectifier: true },
                  {
                    id: constants.PROBLEM_SOLVE_LOADER_MESSAGE,
                    message: PROBLEM_SOLVE_RECTIFIER_LOADER_MESSAGE
                  },
                  {
                    ...responseMessageStep,
                    payloadType: constants.PROBLEM_SOLVE_RECTIFIER,
                    content: aiResponse[1],
                    apiContent: `${aiResponse[0]}<div>${PROBLEM_SOLVE_RECTIFIER_LOADER_MESSAGE}</div>${aiResponse[1]}`
                  }
                );
              }
            } else if (userInput) {
              stepsArray.push(userMessageStep, responseMessageStep);
            } else {
              stepsArray.push(responseMessageStep);
            }
            break;
          case constants.SUMMARY_INTENT:
            responseMessageStep = {
              id: constants.SUMMARY_CONTENT,
              fromHistory: true,
              data: {
                summaryData: {
                  summaryText: historyResponse,
                  requestId,
                  responseId
                },
                summaryQuizButton: false,
                bookID: bookId,
                showFormat: false,
                pageId: pageIds,
                chapterData
              }
            };
            if (userInput) {
              stepsArray.push(userMessageStep, responseMessageStep);
            } else {
              stepsArray.push(responseMessageStep);
            }
            break;
          case constants.QUIZ_INTENT:
            if (quizOption === constants.FREE_RESPONSE) {
              responseMessageStep = {
                id: constants.FREE_RESPONSE_QUIZ,
                requestId,
                responseId,
                fromHistory: true,
                data: {
                  quizData: quizQuestionData,
                  bookID: bookId,
                  disableOptions: true,
                  pageId: pageIds,
                  chapterData,
                  currentQuestionIndex: 0, // by default giving 0
                  message: quizQuestionData && (quizQuestionData.question || quizQuestionData.question_stem),
                  isReadyNext: false,
                  showSummary: false,
                  isLastQuestion: false,
                  displayQuestion: true,
                  showOptions: false
                }
              };
            } else {
              responseMessageStep = {
                id: constants.QUIZ_CONTENT,
                requestId,
                responseId,
                fromHistory: true,
                data: {
                  quizData: quizQuestionData,
                  bookID: bookId,
                  disableOptions: true,
                  pageId: pageIds,
                  chapterData,
                  currentQuestionIndex: 0 // by default giving 0
                }
              };
            }

            if (userInput) {
              stepsArray.push(userMessageStep, responseMessageStep);
            } else {
              stepsArray.push(responseMessageStep);
            }
            break;
          default:
            break;
        }
      });
    }
    return stepsArray;
  }

  static groupHistoryTitle = (titleArray) => {
    const groupingFormat = [
      {
        id: 'today',
        title: 'Today',
        list: []
      },
      {
        id: 'this_week',
        title: 'This week',
        list: []
      },
      {
        id: 'last_week',
        title: 'Last week',
        list: []
      },
      {
        id: 'two_weeks_ago',
        title: '2 weeks ago',
        list: []
      },
      {
        id: 'three_weeks_ago',
        title: '3 weeks ago',
        list: []
      },
      {
        id: 'last_month',
        title: 'Last month',
        list: []
      },
      {
        id: 'two_months_ago',
        title: '2 months ago',
        list: []
      },
      {
        id: 'three_months_ago',
        title: '3 months ago',
        list: []
      },
      {
        id: 'older',
        title: 'Older',
        list: []
      }
    ];
    const today = new Date();
    const thisWeek = new Date().setDate(today.getDate() - 7);
    const lastWeek = new Date().setDate(today.getDate() - 14);
    const twoWeekAgo = new Date().setDate(today.getDate() - 21);
    const threeWeekAgo = new Date().setDate(today.getDate() - 28);
    const lastMonth = new Date().setDate(today.getDate() - 60);
    const twoMonthAgo = new Date().setDate(today.getDate() - 90);
    const threeMonthAgo = new Date().setDate(today.getDate() - 120);
    titleArray.forEach((titleObject) => {
      const { updatedOn } = titleObject;
      const updateDate = new Date(titleObject.updatedOn);
      if (today.toDateString() === updateDate.toDateString()) {
        groupingFormat[0].list.push(titleObject);
      } else if (thisWeek <= updatedOn) {
        groupingFormat[1].list.push(titleObject);
      } else if (lastWeek <= updatedOn) {
        groupingFormat[2].list.push(titleObject);
      } else if (twoWeekAgo <= updatedOn) {
        groupingFormat[3].list.push(titleObject);
      } else if (threeWeekAgo <= updatedOn) {
        groupingFormat[4].list.push(titleObject);
      } else if (lastMonth <= updatedOn) {
        groupingFormat[5].list.push(titleObject);
      } else if (twoMonthAgo <= updatedOn) {
        groupingFormat[6].list.push(titleObject);
      } else if (threeMonthAgo <= updatedOn) {
        groupingFormat[7].list.push(titleObject);
      } else {
        groupingFormat[8].list.push(titleObject);
      }
    });
    return groupingFormat;
  }

  static getHistoryFormat = (updatedOn) => {
    const updateDate = new Date(updatedOn);
    const today = new Date();
    const thisWeek = new Date().setDate(today.getDate() - 7);
    const lastWeek = new Date().setDate(today.getDate() - 14);
    const twoWeekAgo = new Date().setDate(today.getDate() - 21);
    const threeWeekAgo = new Date().setDate(today.getDate() - 28);
    const lastMonth = new Date().setDate(today.getDate() - 60);
    const twoMonthAgo = new Date().setDate(today.getDate() - 90);
    const threeMonthAgo = new Date().setDate(today.getDate() - 120);
    if (today.toDateString() === updateDate.toDateString()) {
      return 'Today';
    } if (thisWeek <= updatedOn) {
      return 'This week';
    } if (lastWeek <= updatedOn) {
      return 'Last week';
    } if (twoWeekAgo <= updatedOn) {
      return 'Two weeks ago';
    } if (threeWeekAgo <= updatedOn) {
      return 'Three weeks ago';
    } if (lastMonth <= updatedOn) {
      return 'Last month';
    } if (twoMonthAgo <= updatedOn) {
      return 'Two months ago';
    } if (threeMonthAgo <= updatedOn) {
      return 'Three months ago';
    }
    return 'Older';
  }

  static capitalizeFirstLetter = (str) => str && str.charAt(0).toUpperCase() + str.slice(1)

  static maxEsScorePremiumVideo = (videos, esScore) => {
    let maxScore = 0;
    let premiumVideo = null;
    videos.forEach((video) => {
      if ((video.esScore > maxScore) && (video.esScore > esScore)) {
        maxScore = video.esScore;
        premiumVideo = video;
      }
    });
    return premiumVideo;
  }

  //! this code is work around to pause the video in Study component.
  static pauseVideoOnBotClose = () => {
    try {
      const videoElements = Array.from(document.querySelectorAll('#message_block video'));
      if (videoElements && videoElements.length) {
        videoElements.forEach((video) => {
          if (!video.paused) {
            video.pause();
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  static sanitizeMessage = (message) => message && message.replace(/<\/?a[^>]*>/g, '')

  static loadExternalJS = (scriptURL) => {
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.src = scriptURL;
    scriptElement.async = true;
    scriptElement.onload = () => {
      // Custom tokenizer for inline math delimiters: ``, \( ... \) and \[ ... \]
      const mathTokenizer = {
        name: 'MATH_EXPRESSION',
        level: 'inline',
        // eslint-disable-next-line consistent-return
        tokenizer(src) {
          // Match inline math with `...`
          const inlineASCIIMathMatch = /^`(.*?)`/.exec(src);
          if (inlineASCIIMathMatch) {
            return {
              type: 'MATH_EXPRESSION',
              subType: 'ASCII',
              raw: inlineASCIIMathMatch[0],
              text: inlineASCIIMathMatch[1],
              tokens: []
            };
          }

          // Match inline math with \( ... \)
          const inlineMathMatch = /^\\\((.*?)\\\)/.exec(src);
          if (inlineMathMatch) {
            return {
              type: 'MATH_EXPRESSION',
              subType: 'LATEX_BRACKET',
              raw: inlineMathMatch[0],
              text: inlineMathMatch[1],
              tokens: []
            };
          }

          // Match inline math with \[ ... \] (treat as inline math)
          const blockMathMatch = /^\\\[(.*?)\\\]/s.exec(src); // Added "s" for dot-all mode to match multi-line
          if (blockMathMatch) {
            return {
              type: 'MATH_EXPRESSION',
              subType: 'LATEX_SQ_BRACKET',
              raw: blockMathMatch[0],
              text: blockMathMatch[1],
              tokens: []
            };
          }
        },

        // eslint-disable-next-line consistent-return
        renderer(token) {
        // Render both inline and block delimiters as inline math
          if (token.type === 'MATH_EXPRESSION' && token.subType === 'LATEX_BRACKET') {
            return `\\(${token.text}\\)`; // Render inline math
          }
          if (token.type === 'MATH_EXPRESSION' && token.subType === 'LATEX_SQ_BRACKET') {
            return `\\[${token.text}\\]`; // Render inline math
          }
          if (token.type === 'MATH_EXPRESSION' && token.subType === 'ASCII') {
            return `\`${token.text}\``;
          }
        }
      };
      // custom math extension to Marked.js
      // eslint-disable-next-line no-undef
      marked.use({ extensions: [mathTokenizer] });
    };

    document.body.appendChild(scriptElement);
  }

  static pageNavigationAnchorLinks = (id) => {
    const linkCount = document.getElementById(id)?.querySelectorAll('a.citationlink')?.length;
    if (linkCount) {
      return `${linkCount}`;
    }
    return '0';
  }

  static contentMediaImages = (id) => {
    const imgCount = document.getElementById(id)?.querySelectorAll('img')?.length;
    if (imgCount) {
      return 'Img';
    }
    return null;
  }

  static targetUrlLink = (pageId) => {
    const urlObj = new URL(window.location.href); // Create a URL object
    const pathSegments = urlObj.pathname.split('/'); // Split the pathname into segments
    pathSegments[pathSegments.length - 1] = pageId; // Replace the last segment with the new parameter
    urlObj.pathname = pathSegments.join('/'); // Update the pathname
    return urlObj.toString(); // Return the new URL as a string
  }

  static premiumVideoData = (premiumVideo) => ({
    assetId: premiumVideo[0]?.id,
    assetTitle: premiumVideo[0]?.params?.title,
    assetSource: premiumVideo[0]?.type,
    channelFlow: premiumVideo[0]?.locationData?.type,
    channelType: premiumVideo[0]?.channelId,
    topic: premiumVideo[0]?.locationData?.topicTitle,
    topicId: premiumVideo[0]?.locationData?.topicId,
    subject: premiumVideo[0]?.locationData?.chapterTitle
  })
}

/* eslint-disable no-debugger */
/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file ChatHistoryStyles.jsx : A File that handles the overall chat history styles
 * @author Sarathkumar Venkatachalam
 */

import ThemeConstants from '../../Common/ThemeConstants';

const getBorderColor = (props) => (props.disabled ? ThemeConstants[props.masterTheme][props.theme].historySearchBorderColor : ThemeConstants[props.masterTheme][props.theme].historyRenameBorderColor);

const ChatHistoryStyles = {
  chatHistoryHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '28px 16px 8px 24px',
    minHeight: 40
  },
  chatHistoryTitle: {
    fontSize: 32,
    fontFamily: 'TT Commons',
    fontWeight: 700,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    marginRight: 10,
    lineHeight: '32px'
  },
  historyCloseButton: {
    padding: 8
  },
  historySearchRoot: {
    padding: '4px 12px 4px 16px',
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 40px)',
    borderRadius: 8,
    boxSizing: 'border-box',
    margin: '8px auto 12px auto',
    boxShadow: 'none',
    backgroundColor: (props) => ThemeConstants[props.masterTheme][props.theme].historySearchBgColor,
    border: (props) => ThemeConstants[props.masterTheme][props.theme].historySearchBorderColor,
    minHeight: 'auto !important',
    '&:hover': {
      border: (props) => getBorderColor(props)
    }
  },
  historySearchInput: {
    minHeight: 24,
    marginLeft: 10,
    flex: 1,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    '&::placeholder': {
      color: (props) => ThemeConstants[props.masterTheme][props.theme].placeHolderTextColor
    },
    '& input': {
      margin: '2px 0px 0px 0px',
      fontFamily: 'TT Commons',
      fontSize: 18,
      fontWeight: 400,
      color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
    }
  },
  historySearchIconButton: {
    padding: 0,
    height: 20,
    marginBottom: 1
  },
  closeSearchStyle: {
    padding: 5
  },
  historyListWrapper: {
    margin: '20px 0px 0 0px',
    boxSizing: 'border-box',
    paddingBottom: 12
  },
  groupDividerStyle: {
    backgroundColor: (props) => ThemeConstants[props.masterTheme][props.theme].historyListBorderColor,
    margin: '0 16px 0 24px'
  },
  scrollDivider: {
    borderTop: (props) => ThemeConstants[props.masterTheme][props.theme].historyListBorder
  },
  historyScrollBar: {
    height: 'calc(100% - 145px) !important',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    overflowY: 'scroll',
    '& hr': {
      '&:last-child': {
        backgroundColor: 'initial',
        margin: '0 !important',
        height: '0 !important'
      }
    },
    '&::-webkit-scrollbar': {
      width: 7,
      padding: 4
    },
    '&::-webkit-scrollbar-track': {
      background: (props) => ThemeConstants[props.masterTheme][props.theme].bgColor,
      borderRadius: '0px 4px 4px 0px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: (props) => ThemeConstants[props.masterTheme][props.theme].scrollThumbBgColor,
      borderRadius: 4,
      width: 6
    }
  },
  historyListTitleStyle: {
    fontFamily: 'TT Commons',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].placeHolderTextColor,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.24px',
    marginBottom: 8,
    paddingLeft: 24
  },
  historyListItemWrapper: {
    alignItems: 'start',
    width: '100%',
    padding: '8px 16px 8px 24px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: (props) => ThemeConstants[props.masterTheme][props.theme].radioHoverColor,
      color: (props) => ThemeConstants[props.masterTheme][props.theme].historyListTextHoverColor
    },
    '&:focus': {
      backgroundColor: (props) => ThemeConstants[props.masterTheme][props.theme].radioHoverColor,
      color: (props) => ThemeConstants[props.masterTheme][props.theme].historyListTextHoverColor
    }
  },
  activeHistoryListItemWrapper: {
    background: (props) => ThemeConstants[props.masterTheme][props.theme].historyListActiveBgColor,
    borderLeft: (props) => ThemeConstants[props.masterTheme][props.theme].historyListActiveBorderColor,
    padding: '8px 16px',
    '&:hover': {
      background: 'var(--Swatch-Light-200, #E8EEFA)'
    }
  },
  listFocusStyle: {
    backgroundColor: (props) => ThemeConstants[props.masterTheme][props.theme].radioHoverColor
  },
  historyListItemStyle: {
    fontFamily: 'TT Commons',
    fontSize: 18,
    fontWeight: 400,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    lineHeight: '24px',
    letterSpacing: '0.24px',
    overflow: 'hidden',
    '-webkit-line-clamp': 2,
    lineClamp: 2,
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
    wordBreak: 'break-word',
    paddingLeft: 8,
    paddingRight: 8,
    margin: 0,
    '& >span': {
      wordBreak: 'break-word',
      fontFamily: 'TT Commons',
      fontSize: 18,
      fontWeight: 400,
      color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
      lineHeight: '24px',
      letterSpacing: '0.24px',
      overflow: 'hidden',
      '-webkit-line-clamp': 2,
      lineClamp: 2,
      '-webkit-box-orient': 'vertical',
      display: '-webkit-box',
      '&:hover': {
        color: (props) => ThemeConstants[props.masterTheme][props.theme].historyListTextHoverColor
      },
      '&:focus': {
        color: (props) => ThemeConstants[props.masterTheme][props.theme].historyListTextHoverColor
      }
    }
  },
  activeHistoryListItemStyle: {
    fontWeight: 700,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].historyListActiveTextColor,
    '& span': {
      fontWeight: 700,
      color: (props) => ThemeConstants[props.masterTheme][props.theme].historyListActiveTextColor,
      '&:hover': {
        color: (props) => ThemeConstants[props.masterTheme][props.theme].historyListActiveTextColor
      }
    }
  },
  listItemIconStyle: {
    minWidth: 16
  },
  menuItemTextStyle: {
    paddingLeft: 12,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  poperStyle: {
    zIndex: '1302 !important'
  },
  paperBoxStyle: {
    boxShadow: '0px 1px 5px 0px #0000001F, 0px 2px 2px 0px #00000024, 0px 3px 1px -2px #00000033'
  },
  menuButtonStyle: {
    padding: 4,
    border: 'none'
  },
  menuButtonActiveStyle: {
    backgroundColor: (props) => ThemeConstants[props.masterTheme][props.theme].historyListHoverColor
  },
  menuContainer: {
    padding: 8,
    background: (props) => ThemeConstants[props.masterTheme][props.theme].bgColor
  },
  menuItemStyle: {
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: (props) => ThemeConstants[props.masterTheme][props.theme].radioHoverColor
    }
  },
  noSearchResultStyle: {
    fontFamily: 'TT Commons',
    fontSize: 18,
    fontWeight: 400,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    lineHeight: '24px',
    letterSpacing: '0.24px',
    textAlign: 'center',
    wordBreak: 'break-word'
  },
  fbold: {
    fontWeight: 700
  },
  noSearchIconStyle: {
    width: 114,
    margin: '0 auto'
  },
  noSearchResultWrapper: {
    padding: 16
  },
  historyLoader: {
    display: 'flex',
    height: 'inherit',
    alignItems: 'center'
  },
  historyNoData: {
    fontFamily: 'TT Commons',
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '24px',
    textAlign: 'center',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  renameInputField: {
    height: 40,
    width: '100%',
    boxSizing: 'border-box',
    padding: 0,
    borderRadius: 5,
    fontFamily: 'TT Commons',
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: 0.23999999463558197,
    textAlign: 'left',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  filledInputStyle: {
    '&:focus-visible': {
      backgroundColor: (props) => ThemeConstants[props.masterTheme][props.theme].radioHoverColor,
      outline: (props) => ThemeConstants[props.masterTheme][props.theme].welcomeBtnsBgHoverColor,
      borderRadius: 5
    }
  },
  blankInputStyle: {
    border: '2px solid #e39f9f',
    '&:focus-visible': {
      backgroundColor: (props) => ThemeConstants[props.masterTheme][props.theme].radioHoverColor,
      outline: '#e39f9f',
      borderRadius: 5
    }
  },
  renameButtonStyle: {
    padding: 5
  },
  renameInputRoot: {
    padding: '4px 12px 4px 16px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: 4,
    boxSizing: 'border-box',
    boxShadow: 'none',
    margin: 0,
    backgroundColor: (props) => ThemeConstants[props.masterTheme][props.theme].historyBgColor,
    border: (props) => ThemeConstants[props.masterTheme][props.theme].historyRenameBorderColor,
    minHeight: 'auto !important'
  },
  renameInput: {
    minHeight: 24,
    flex: 1,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    '& input': {
      fontFamily: 'TT Commons',
      fontSize: 16,
      fontWeight: 400,
      color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
    }
  },
  renamePaperStyle: {
    width: 'auto !important'
  },
  activeMessageStyle: {
    marginTop: 4
  }
};

export default ChatHistoryStyles;

/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file WelcomeMessage.jsx
 * @author Sarathkumar Venkatachalam
 */

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import messages from '../../defaultMessages';
import * as constants from '../../Common/constants';
import { eventAction, gaEvents } from '../../analytics/analytics';
import ChatComponentStyles from './ChatComponentStyles';

const WelcomeMessage = ({
  classes,
  intl,
  bookTitle,
  onOptionClick,
  onOptionClickTriggerGA,
  isFirstTimeAITutorUser,
  isNewTopic,
  isLastQuestion,
  isFromStopQuiz,
  isFromUnknownIntentType,
  ariaLiveStatus,
  handleGAEvents,
  aiTutorAutoOpenCommands,
  onPredefinedQueryClick,
  botAutoOpen,
  showHistory,
  showStarterPrompt,
  isExpanded,
  showSummary,
  showPractice,
  showRecommendedPrompts,
  recommendedPrompts
}) => {
  const containerRef = useRef(null);
  useEffect(() => {
    if (isNewTopic && !isLastQuestion && !isFromStopQuiz) {
      containerRef.current.focus();
    }
    if (isFromUnknownIntentType) {
      const additionalFields = {
        event_label: null,
        event_value: null,
        event_action: eventAction.aiErrorMessage,
        error_code: 'unknown'
      };
      gaEvents(handleGAEvents, additionalFields);
    }
  }, []);
  const { formatMessage } = intl;
  const getWelcomeMessage = () => {
    let welcomeText = formatMessage(messages.welcomeMessage);
    if (isNewTopic && !isFromStopQuiz) {
      welcomeText = formatMessage(messages.newTopicWelcomeMessage);
    } else if (!isFirstTimeAITutorUser && !isNewTopic && !isFromStopQuiz) {
      welcomeText = formatMessage(messages.welcomeBackMessage);
    } else if (isNewTopic && isFromStopQuiz) {
      welcomeText = '';
    }
    return welcomeText;
  };
  const getSubWelcomeMessage = () => {
    let subWelcomeText = formatMessage(messages.welcomeMessage1);
    // if (isFromContentSelector) {
    //   subWelcomeText = formatMessage(messages.welcomeCSMessage1);
    // }
    if (isNewTopic && !isFromStopQuiz) {
      subWelcomeText = showRecommendedPrompts ? '' : formatMessage(messages.newTopicWelcomeMessage1);
    } else if (isNewTopic && isFromStopQuiz) {
      subWelcomeText = formatMessage(messages.stopQuizWelcomeMessage);
    }
    return subWelcomeText;
  };

  // const getFooterWelcomeMessage = () => {
  //   let footerWelcomeText = formatMessage(messages.welcomeFooterMessage1);
  //   if (!isFirstTimeAITutorUser) {
  //     footerWelcomeText = formatMessage(messages.welcomeBackFooterMessage1);
  //   }
  //   return footerWelcomeText;
  // };
  const buttonStyle = isExpanded ? `${classes.welcomeBtnStyles} ${classes.expandedActionButtonStyle}` : classes.welcomeBtnStyles;

  const getPromptList = (promptArray, recommendedPrompt) => (
    <div className={classes.btnWrapper}>
      {
          promptArray.map((prompt) => (
            <Button
              component="button"
              onClick={() => { onPredefinedQueryClick(recommendedPrompt ? prompt : prompt.command, !recommendedPrompt); }}
              className={classes.predefinedBtnStyles}
            >
              {recommendedPrompt ? prompt : prompt.text}
            </Button>
          ))
        }
    </div>
  );

  const getCtaButtons = () => (
    <div className={classes.welcomeBtnsWrapper}>
      { showSummary && (
      <Button
        component="button"
        onClick={() => { onOptionClickTriggerGA(); onOptionClick(constants.SUMMARY, isFromStopQuiz, null, false, isFromUnknownIntentType); }}
        className={buttonStyle}
      >
        {formatMessage(messages.welcomeBtnSummarize)}
      </Button>
      )}
      {
        showPractice && (
          <Button
            component="button"
            onClick={() => { onOptionClickTriggerGA(); onOptionClick(constants.QUIZ, isFromStopQuiz, null, false, isFromUnknownIntentType); }}
            className={buttonStyle}
          >
            {formatMessage(messages.welcomeBtnPractice)}
          </Button>
        )
      }
      <Button
        component="button"
        onClick={() => { onOptionClickTriggerGA(); onOptionClick(constants.DISCUSS, isFromStopQuiz, null, false, isFromUnknownIntentType); }}
        className={buttonStyle}
      >
        {formatMessage(messages.welcomeBtnExplain)}
      </Button>
    </div>
  );

  const getWelcomeMessageContainer = () => (
    <>
      <Typography variant="paragraph" className={classes.welcomeStyle}>
        {getWelcomeMessage()}
        {' '}
        {!isNewTopic && isFirstTimeAITutorUser ? (
          <>
            <strong>{bookTitle}</strong>
            .
          </>
        ) : ''}
      </Typography>
      <Typography variant="paragraph" className={classes.welcomeStyle}>
        {
          isFirstTimeAITutorUser || isNewTopic ? (!showRecommendedPrompts || isFromStopQuiz) && getSubWelcomeMessage() : (
            <>
              {' '}
              {formatMessage(messages.subWelcomeMessage1)}
              <strong>{bookTitle}</strong>
              {showRecommendedPrompts ? '' : `${formatMessage(messages.subWelcomeMessage2)} :`}
            </>
          )
        }
      </Typography>
    </>
  );
  return (
    <div
      ref={containerRef}
      className={(!isFromStopQuiz && !isFromUnknownIntentType)
        ? `${classes.welcomeWrapper}
          ${(isFirstTimeAITutorUser && !isNewTopic) ? classes.welcomeWrapperFaded : ''}
          ${((!showRecommendedPrompts && (botAutoOpen || showStarterPrompt) && aiTutorAutoOpenCommands?.commands?.length) || ((showRecommendedPrompts && !isNewTopic) || (showRecommendedPrompts && isNewTopic && recommendedPrompts && recommendedPrompts.length > 0))) ? classes.JustifyContent : ''}
          ${showHistory ? classes.addBlur : ''}`
        : ''}
      aria-label="chat history"
      role="region"
      aria-live={ariaLiveStatus}
      tabIndex={(isNewTopic && !isFromStopQuiz) ? 0 : -1}
    >
      {
          /* eslint-disable no-nested-ternary */
          (showRecommendedPrompts && !isFromStopQuiz && !isLastQuestion && !isFromUnknownIntentType) ? (
            <>
              <div>
                {
                  getWelcomeMessageContainer()
                }
                {(recommendedPrompts && recommendedPrompts.length > 0) && (
                  <div className={`${classes.predefinedBtnWrapper} ${classes.alignmentTop}`}>
                    <Typography variant="paragraph" className={classes.welcomeStyle}>
                      {
                        formatMessage(messages.recommendationPromptTitle)
                      }
                    </Typography>
                    {
                      getPromptList(recommendedPrompts, true)
                    }
                  </div>
                )}
              </div>
              <div>
                <Typography variant="paragraph" className={classes.welcomeStyle}>
                  {
                  (recommendedPrompts && recommendedPrompts.length > 0) ? formatMessage(messages.recommendationPromptCtaTitle) : formatMessage(messages.recommendationCtaTitle)
                  }
                </Typography>
                {
                  getCtaButtons()
                }
              </div>
            </>
          ) : (((botAutoOpen || showStarterPrompt) && !isFromStopQuiz && !isLastQuestion && !isFromUnknownIntentType) ? (
            <>
              {(aiTutorAutoOpenCommands && aiTutorAutoOpenCommands.commands && aiTutorAutoOpenCommands.commands.length > 0) && (
                <div className={`${classes.predefinedBtnWrapper}`}>
                  <Typography variant="paragraph" className={classes.welcomeStyle}>
                    {
                      formatMessage(messages.autoOpenWelcomeHeaderMessage)
                    }
                  </Typography>
                  {
                    getPromptList(aiTutorAutoOpenCommands.commands, false)
                  }
                </div>
              )}
              <div>
                <Typography variant="paragraph" className={classes.welcomeStyle}>
                  {
                    (aiTutorAutoOpenCommands && aiTutorAutoOpenCommands.commands && aiTutorAutoOpenCommands.commands.length > 0)
                      ? formatMessage(messages.autoOpenWelcomeMessage) : getWelcomeMessageContainer()
                  }
                </Typography>
                {getCtaButtons()}
              </div>
            </>
          ) : (
            <>
              <div style={{ marginBottom: 2 }}>
                {!isLastQuestion
                  ? (
                    <>
                      {!isFromUnknownIntentType
                        ? getWelcomeMessageContainer() : (
                          <Typography variant="paragraph" className={classes.welcomeStyle}>
                            {formatMessage(messages.unknownIntentTypeInitialMessage)}
                          </Typography>
                        )}
                    </>
                  )
                  : (
                    <Typography variant="paragraph" className={classes.welcomeStyle}>
                      That was the last question. What&apos;s next?
                    </Typography>
                  )}
              </div>
              {getCtaButtons()}
            </>
          )
          )
      }
    </div>
  );
};

WelcomeMessage.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  onOptionClickTriggerGA: PropTypes.func.isRequired,
  isFirstTimeAITutorUser: PropTypes.bool.isRequired,
  bookTitle: PropTypes.string.isRequired,
  isNewTopic: PropTypes.bool.isRequired,
  isLastQuestion: PropTypes.bool.isRequired,
  isFromStopQuiz: PropTypes.bool,
  isFromUnknownIntentType: PropTypes.bool,
  ariaLiveStatus: PropTypes.string,
  handleGAEvents: PropTypes.func,
  aiTutorAutoOpenCommands: PropTypes.object.isRequired,
  onPredefinedQueryClick: PropTypes.func.isRequired,
  botAutoOpen: PropTypes.bool.isRequired,
  showHistory: PropTypes.bool.isRequired,
  showStarterPrompt: PropTypes.bool,
  isExpanded: PropTypes.bool.isRequired,
  showSummary: PropTypes.bool.isRequired,
  showPractice: PropTypes.bool.isRequired,
  showRecommendedPrompts: PropTypes.bool.isRequired,
  recommendedPrompts: PropTypes.array
};

WelcomeMessage.defaultProps = {
  isFromStopQuiz: false,
  ariaLiveStatus: 'off',
  handleGAEvents: () => {},
  isFromUnknownIntentType: false,
  showStarterPrompt: false,
  recommendedPrompts: []
};

export default withStyles(ChatComponentStyles)(injectIntl(WelcomeMessage));

/* eslint-disable no-debugger */
/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file FeedbackStyles.jsx : A Component that handles the overall Feedback component styles
 * @author Arifhusain Soudagar
 */

import ThemeConstants from '../../Common/ThemeConstants';

const FeedbackStyles = () => ({
  //* FreeResponseQuizResult styles
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5
  },
  actionMultiContainer: {
    marginTop: 5
  },
  correctAnswerWrapper: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontSize: 16,
    marginTop: 16,
    marginBottom: 0,
    display: 'block',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  exportList: {
    border: '1px solid #BCC1CB',
    borderRadius: 4,
    margin: '0 auto',
    listStyle: 'none',
    background: (props) => ThemeConstants[props.masterTheme][props.theme].bgColor,
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 5px rgb(0 0 0 / 12%)',
    width: 240
  },
  iconRootStyle: {
    minWidth: 20,
    marginRight: 7
  },
  textRootStyle: {
    fontSize: 16,
    fontFamily: 'TT Commons',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  poperStyle: {
    zIndex: '99999 !important'
  },
  iconsTooltip: {
    padding: 8,
    width: 'fit-content',
    background: '#161C29',
    borderRadius: '4px',
    color: 'white',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.24px'
  },
  tooltipArrow: {
    color: '#161C29'
  },
  listItemRootStyle: {
    height: 24,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 16
  },
  listAlignment: {
    marginBottom: 0
  },
  alignmentRight: {
    textAlign: 'right'
  },
  summaryData: {
    fontSize: 16,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    fontFamily: 'TT Commons',
    display: 'block'
  },
  alignmentBottom: {
    marginBottom: '0px !important'
  },
  questionStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    paddingBottom: 16
  },
  lastQuestionText: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    paddingBottom: 16,
    marginTop: 15,
    display: 'block'
  },
  questionSpace: {
    marginTop: 16
  },
  NextQuestionPromptContainer__actions: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    gap: 10
  },
  NextQuestionPromptContainer__actions__no: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'flex-end',
    letterSpacing: '0.4px',
    textDecorationLine: 'underline',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  NextQuestionPromptContainer__actions__yes: {
    padding: '8px 24px',
    width: 76,
    height: 40,
    border: '1px solid #BCC1CB',
    borderRadius: '24px',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'none'
  },
  NextQuestionPromptContainer__actions__yes__textNode: {
    width: 28,
    height: 24,
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'flex-end',
    letterSpacing: '0.4px',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  disabledButtonStyle: {
    opacity: '.5',
    color: (props) => `${ThemeConstants[props.masterTheme][props.theme].textColor} !important`
  },
  //* FormatChange styles
  formatWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  formatStyle: {
    color: (props) => ThemeConstants[props.masterTheme][props.theme].formatColor,
    fontSize: 14,
    fontFamily: 'TT Commons'
  },
  //* NegativeFeedback Styles
  feedbackContainer: {
    width: '100%',
    background: 'linear-gradient(116.96deg, rgba(5, 17, 42, 0.5) 25.6%, rgba(49, 1, 53, 0.5) 53.62%), linear-gradient(116.96deg, #310135 30.84%, #05112A 68.52%), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
    borderRadius: 14,
    padding: 24,
    position: 'absolute',
    boxSizing: 'border-box',
    bottom: 0,
    left: 0,
    zIndex: '999999 !important',
    border: '1px solid #FFFFFF'
  },
  slideUp: {
    animation: '$bottomToTop 250ms ease',
    animationDirection: 'normal',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
    transform: 'translateY(40%)',
    transitionTimingFunction: 'cubic-bezier(0,0,0.2,1)'
  },
  thankyouSlideUp: {
    animation: '$bottomToTop 250ms ease',
    animationDirection: 'normal',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
    transform: 'translateY(10%)',
    transitionTimingFunction: 'cubic-bezier(0,0,0.2,1)'
  },
  '@keyframes bottomToTop': {
    to: {
      transform: 'translateY(0%)'
    }
  },
  slideDown: {
    animation: '$topToBottom 200ms ease',
    animationDirection: 'normal',
    animationIterationCount: 1,
    animationFillMode: 'forwards',
    transform: 'translateY(0%)'
  },
  '@keyframes topToBottom': {
    to: {
      transform: 'translateY(100%)'
    }
  },
  feedbackContainer__Header: {
    width: '100%'
  },
  feedbackContainer__Header__text: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '28px',
    color: '#FEFEFE'
  },
  feedbackDescription: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#FEFEFE',
    marginTop: 8
  },
  textBoxStyle: {
    overflowY: 'auto',
    maxHeight: 96,
    padding: 0,
    background: '#020917',
    border: '2px solid #989BA3',
    borderRadius: '4px',
    display: 'initial',
    overflowX: 'hidden',
    height: 96,
    color: '#FEFEFE',
    marginTop: 24,
    '&:placeholder': {
      color: '#989BA3'
    }
  },
  textBoxRequiredStyle: {
    border: '2px solid #EA0B7E'
  },
  textBoxAlignmentTop: {
    marginTop: 10
  },
  textBoxInputStyle: {
    padding: '12px 16px',
    height: 64,
    '&::placeholder': {
      color: '#989BA3',
      opacity: 1
    }
  },
  underlineStyle: {
    '&:before': {
      borderBottom: 'none !important'
    },
    '&:after': {
      borderBottom: 'none !important'
    }
  }, // TODO Need to Revisit this
  sendButton: {
    background: 'linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%)',
    padding: '8px 24px',
    color: '#fefefe',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    width: 88,
    height: 40,
    borderRadius: 24,
    borderColor: 'transparent',
    textTransform: 'none',
    fontFamily: 'TT Commons',
    cursor: 'pointer'
  },
  cancelStyle: {
    background: 'linear-gradient(116.96deg, rgba(5, 17, 42, 0.5) 25.6%, rgba(49, 1, 53, 0.5) 53.62%), linear-gradient(116.96deg, #310135 30.84%, #05112A 68.52%), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
    padding: '8px 24px',
    color: '#fefefe',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    width: 103,
    height: 40,
    borderRadius: 24,
    border: '1px solid #393F4A',
    textTransform: 'none',
    fontFamily: 'TT Commons',
    cursor: 'pointer',
    marginRight: 12
  },
  feedbackActionWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24
  },
  feedbackSubmitHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  alignMessageCenter: {
    textAlign: 'center'
  },
  sendButtonDisabled: {
    color: '#FEFEFE !important',
    opacity: '0.4'
  },
  PostivePopUpHeading: {
    width: 360,
    height: 32,
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '25px',
    lineHeight: '32px',
    color: '#020917'
  },
  selectedStyle: {
    color: (props) => `${ThemeConstants[props.masterTheme][props.theme].textColor} !important`
  },
  checkboxRoot: {
    paddingTop: '0px',
    alignItems: 'center'
  },
  checkboxRootStyle: {
    padding: 5,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    '&:hover': {
      background: 'linear-gradient(116.96deg, rgba(5, 17, 42, 0.5) 25.6%, rgba(49, 1, 53, 0.5) 53.62%), linear-gradient(116.96deg, #310135 30.84%, #05112A 68.52%), linear-gradient(0deg, #FFFFFF, #FFFFFF)'
    },
    '&:focus': {
      background: 'linear-gradient(116.96deg, rgba(5, 17, 42, 0.5) 25.6%, rgba(49, 1, 53, 0.5) 53.62%), linear-gradient(116.96deg, #310135 30.84%, #05112A 68.52%), linear-gradient(0deg, #FFFFFF, #FFFFFF)'
    },
    '&.Mui-focusVisible': {
      background: 'linear-gradient(116.96deg, rgba(5, 17, 42, 0.5) 25.6%, rgba(49, 1, 53, 0.5) 53.62%), linear-gradient(116.96deg, #310135 30.84%, #05112A 68.52%), linear-gradient(0deg, #FFFFFF, #FFFFFF)'
    }
  },
  checkboxLabel: {
    fontFamily: 'TT Commons',
    color: '#FEFEFE'
  },
  closeIconStyle: {
    position: 'absolute',
    right: 10,
    top: 20
  },
  //* Postive styles
  feedbackContainerPostitive: {
    width: 500,
    background: (props) => ThemeConstants[props.masterTheme][props.theme].bgColor,
    boxShadow: '0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: 12,
    padding: 36,
    boxSizing: 'border-box',
    position: 'relative'
  },
  quizContainer__actions__buttons__next: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 24px',
    width: 106,
    height: 40,
    background: (props) => ThemeConstants[props.masterTheme][props.theme].bgColor,
    border: '1px solid #BCC1CB',
    borderRadius: 18,
    cursor: 'pointer',
    textTransform: 'none',
    fontSize: 18,
    fontWeight: 700,
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor,
    fontFamily: 'TT Commons'
  },
  doneAction: {
    textDecoration: 'underline',
    // width: '100%',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    textDecorationLine: 'underline',
    color: '#655591',
    cursor: 'pointer',
    textAlign: 'right',
    marginTop: 20
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20
  },
  defaultButtonContainer: {
    marginTop: 20
  },
  doneContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 10
  },
  feedbackCloseIconStyle: {
    position: 'absolute',
    right: 20,
    top: 10
  },
  formControl: {
    marginTop: 5,
    marginLeft: 12
  },
  copyWrapperHidden: {
    display: 'none'
  }
});
export default FeedbackStyles;

/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file LikeIcon.jsx
 * @author Sarathkumar Venkatachalam, Vignesh Selvam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import ThemeConstants from '../ThemeConstants';

const Styles = () => ({
  likeIcon: {
    width: 21,
    height: 20
  }
});

const LikeIcon = ({ classes, theme, masterTheme }) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 21 20"
    classes={{
      root: classes.likeIcon
    }}
  >
    <path
      d="M4.825 19.375V6.17505L10.45 0.425C10.6767 0.191667 10.9449 0.0541667 11.2547 0.0125C11.5644 -0.0291667 12.1679 0.0333333 12.455 0.2C12.7383 0.366667 13.4433 0.595833 13.585 0.8875C13.7267 1.17917 13.7667 1.48333 13.695 1.8L12.425 6.17505H15.8125H19.4C19.8 6.17505 20.15 6.32505 20.45 6.62505C20.75 6.92505 20.9 7.475 20.9 7.875V9.92098C20.9 10.0403 20.905 10.1578 20.905 10.2875C20.905 10.4172 20.9 10.5071 20.9 10.65L17.75 17.9C17.602 18.2542 17.3554 18.7571 17.0101 19.005C16.6649 19.253 16.3032 19.375 15.925 19.375H4.825ZM6.62499 7.37505V17.375H15.995L19.105 10.2V8.17505H9.87499L11.705 2.14505L6.62499 7.37505ZM1.5 19.375C1.0875 19.375 0.734375 19.2288 0.440625 18.935C0.146875 18.6413 0 18.0875 0 17.675V7.875C0 7.4625 0.146875 6.9088 0.440625 6.61505C0.734375 6.3213 1.0875 6.17505 1.5 6.17505H4.825V7.875H1.80499V17.375H4.825V19.375H1.5Z"
      fill={ThemeConstants[masterTheme][theme].textColor}
    />
  </SvgIcon>
);

LikeIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string,
  masterTheme: PropTypes.string
};

LikeIcon.defaultProps = {
  theme: 'default',
  masterTheme: 'plus'
};

export default withStyles(Styles)(LikeIcon);

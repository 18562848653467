/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file SearchIcon.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import ThemeConstants from '../ThemeConstants';

const Styles = () => ({
  searchIcon: {
    width: 16,
    height: 16
  }
});

const SearchIcon = ({ classes, theme, masterTheme }) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 16 16"
    classes={{
      root: classes.searchIcon
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9399 10.9638L15.1313 14.1551C15.4008 14.4247 15.4008 14.8616 15.1313 15.1312C14.8618 15.4007 14.4248 15.4007 14.1553 15.1312L10.9639 11.9398C9.87881 12.8116 8.50036 13.3333 7.00008 13.3333C3.50228 13.3333 0.666748 10.4978 0.666748 6.99996C0.666748 3.50216 3.50228 0.666626 7.00008 0.666626C10.4979 0.666626 13.3334 3.50216 13.3334 6.99996C13.3334 8.50024 12.8118 9.87868 11.9399 10.9638ZM7.00008 11.9333C9.72469 11.9333 11.9334 9.72456 11.9334 6.99996C11.9334 4.27535 9.72469 2.06663 7.00008 2.06663C4.27548 2.06663 2.06675 4.27535 2.06675 6.99996C2.06675 9.72456 4.27548 11.9333 7.00008 11.9333Z"
      fill={ThemeConstants[masterTheme][theme].textColor}
    />
  </SvgIcon>
);

SearchIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string,
  masterTheme: PropTypes.string
};

SearchIcon.defaultProps = {
  theme: 'default',
  masterTheme: 'plus'
};

export default withStyles(Styles)(SearchIcon);

/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file ChainIcon.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';

const Styles = () => ({
  copyIcon: {
    width: 16,
    height: 16
  }
});

const ChainIcon = ({ classes }) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 16 16"
    classes={{
      root: classes.chainIcon
    }}
  >
    <path d="M5.64334 7.52871C6.39867 6.77338 7.716 6.77338 8.47134 7.52871L8.94267 8.00004L9.88534 7.05738L9.414 6.58604C8.78534 5.95671 7.94801 5.60938 7.05734 5.60938C6.16667 5.60938 5.32934 5.95671 4.70067 6.58604L3.28601 8.00004C2.6622 8.62585 2.31192 9.47343 2.31192 10.357C2.31192 11.2407 2.6622 12.0882 3.28601 12.714C3.59525 13.0237 3.96265 13.2693 4.36708 13.4365C4.77152 13.6038 5.20501 13.6895 5.64267 13.6887C6.08045 13.6896 6.51408 13.604 6.91864 13.4367C7.3232 13.2694 7.6907 13.0238 8 12.714L8.47134 12.2427L7.52867 11.3L7.05734 11.7714C6.68165 12.1454 6.17312 12.3554 5.64301 12.3554C5.11289 12.3554 4.60436 12.1454 4.22867 11.7714C3.85434 11.3959 3.64414 10.8873 3.64414 10.357C3.64414 9.82682 3.85434 9.31822 4.22867 8.94271L5.64334 7.52871Z" fill="#FEFEFE" />
    <path d="M7.99999 3.28616L7.52865 3.75749L8.47132 4.70016L8.94265 4.22882C9.31834 3.85482 9.82687 3.64484 10.357 3.64484C10.8871 3.64484 11.3956 3.85482 11.7713 4.22882C12.1457 4.60434 12.3559 5.11294 12.3559 5.64316C12.3559 6.17338 12.1457 6.68198 11.7713 7.05749L10.3567 8.47149C9.60132 9.22682 8.28399 9.22682 7.52865 8.47149L7.05732 8.00016L6.11465 8.94282L6.58599 9.41416C7.21465 10.0435 8.05199 10.3908 8.94265 10.3908C9.83332 10.3908 10.6707 10.0435 11.2993 9.41416L12.714 8.00016C13.3378 7.37435 13.6881 6.52677 13.6881 5.64316C13.6881 4.75955 13.3378 3.91197 12.714 3.28616C12.0883 2.66203 11.2407 2.31152 10.357 2.31152C9.47327 2.31152 8.62563 2.66203 7.99999 3.28616Z" fill="#FEFEFE" />
  </SvgIcon>
);

ChainIcon.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(Styles)(ChainIcon);

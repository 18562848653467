/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file VideoCardPlayer.jsx - A Component that handles Video card player
 * @author Arifhusain Soudagar
 */

import React, { useCallback, useState } from 'react';
import { ChannelsProvider, ChannelsVideoCard } from '@study/components';
import { withStyles } from '@material-ui/core/styles';
import '@study/components/node_modules/@aquila/media/build/index.css';
import { intlShape, injectIntl } from 'react-intl';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import CustomMessage from '../../Widgets/CustomMessage';
import {
  defaultConfig, videoConfig, channelsClasses, paywallModalProps
} from '../../Common/ChannelsConfig';
// import { eventAction } from '../../analytics/analytics';
import PayWallSuggestion from '../FeedbackComponent/PayWallSuggestion';
import * as constants from '../../Common/constants';
import messages from '../../defaultMessages';
import {
  clickSubscriptionWallEvent,
  openChannelsFromEReaderEvent,
  userSeesSubscriptionPopUpEvent
} from '../../analytics/analyticsEvents';
import UserMessage from '../../Widgets/UserMessage';
import Utils from '../../Common/Utils';

const styles = () => ({
  videoWrapper: {
    display: 'flex',
    gap: 16,
    flexWrap: 'wrap',
    marginBottom: 16
  }
});

const VideoCardPlayer = ({
  intl,
  classes,
  premiumVideo,
  user,
  onLDTrack,
  env,
  channelId,
  theme,
  masterTheme,
  handleGAEvents,
  bookId,
  productId,
  isChannelsBundleSubscriber,
  initials,
  showPremiumVideo,
  showOptInPremiumMessage,
  hideChannelsPopout
}) => {
  const { formatMessage } = intl;
  const [paywallModalOpen, setPaywallModalOpen] = useState(false);
  const { userId, token } = user;
  const premiumVideoData = Utils.premiumVideoData(premiumVideo);

  const handlePlayStart = useCallback(() => {
    // Call the LD callback to handle the video click event
    if (onLDTrack) {
      onLDTrack(constants.CHANNELS_VIDEO_CLICKED_FROM_GPT);
    }
  }, []);

  const handlePaywallClose = useCallback((event, label) => {
    event.stopPropagation();
    setPaywallModalOpen(false);
    clickSubscriptionWallEvent(handleGAEvents, premiumVideoData, label);
  }, []);

  const handlePaywallShow = useCallback(() => {
    setPaywallModalOpen(true);
    userSeesSubscriptionPopUpEvent(handleGAEvents, premiumVideoData);
  }, []);

  const handleSeeInChannelsLinkClick = () => {
    // Call the analytics event to track the see in channels link click
    openChannelsFromEReaderEvent(handleGAEvents, bookId, productId, isChannelsBundleSubscriber);
  };

  return (
    <>
      {showOptInPremiumMessage ? (
        <div style={{ paddingTop: 24 }}>
          <UserMessage
            key={constants.USER_MESSAGE}
            message={formatMessage(messages.showMeStudyAndExamPrepVideos)}
            initials={initials}
            masterTheme={masterTheme}
            theme={theme}
          />
        </div>
      ) : null}
      {showPremiumVideo ? (
        <>
          <CustomMessage
            headerMessage={showOptInPremiumMessage ? formatMessage(messages.studyAndExamPrepHeadingOptIn) : formatMessage(messages.studyAndExamPrepHeading)}
            masterTheme={masterTheme}
            theme={theme}
          />

          <Box className={classes.videoWrapper}>
            <ChannelsProvider
              env={env}
              channelId={channelId}
              user={{ userId, token }}
            >
              {
            premiumVideo.map((video) => (
              <ChannelsVideoCard
                asset={video}
                config={defaultConfig(video, hideChannelsPopout)}
                videoConfig={videoConfig}
                paywallModalProps={paywallModalProps}
                source={constants.ETEXT_AI_STUDY_TOOL}
                classes={channelsClasses(theme, masterTheme)}
                onThumbnailClick={handlePlayStart}
                onSeeInChannelsLinkClick={handleSeeInChannelsLinkClick}
                trackingGlobalData={{
                  location_in_app: constants.GPT_EVENTS.GPT
                }}
                videoCallbacks={{
                  onPaywallShow: handlePaywallShow,
                  onPlayStart: handlePlayStart
                }}
              />
            ))
          }
            </ChannelsProvider>
          </Box>
        </>
      ) : null}
      {
                paywallModalOpen && (
                <PayWallSuggestion
                  handlePaywallClose={handlePaywallClose}
                  env={env}
                  theme={theme}
                  masterTheme={masterTheme}
                />
                )
            }
    </>
  );
};

VideoCardPlayer.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  theme: PropTypes.string,
  masterTheme: PropTypes.string,
  user: PropTypes.object,
  premiumVideo: PropTypes.array,
  env: PropTypes.string,
  channelId: PropTypes.string,
  handleGAEvents: PropTypes.func,
  bookId: PropTypes.string,
  productId: PropTypes.string,
  isChannelsBundleSubscriber: PropTypes.bool,
  initials: PropTypes.string,
  showPremiumVideo: PropTypes.bool,
  showOptInPremiumMessage: PropTypes.bool,
  onLDTrack: PropTypes.func,
  hideChannelsPopout: PropTypes.bool.isRequired
};

VideoCardPlayer.defaultProps = {
  theme: '',
  masterTheme: '',
  env: '',
  channelId: '',
  premiumVideo: [],
  user: {},
  onLDTrack: () => {},
  bookId: '',
  productId: '',
  isChannelsBundleSubscriber: false,
  initials: '',
  showPremiumVideo: false,
  showOptInPremiumMessage: false,
  handleGAEvents: () => {}
};

export default withStyles(styles)(injectIntl(VideoCardPlayer));

/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file ChatComponent.jsx
 * @author Sarathkumar Venkatachalam, Vignesh Selvam
 */
import React, { memo } from 'react';
import { IntlProvider } from 'react-intl';
import { InternationalSupport } from '@aquila/core';
import msgObject from '../translations';
import Chat from './components/Chatbot';

const ChatComponent = memo((paramsObj) => {
  const intlObj = new InternationalSupport(msgObject, paramsObj.locale);
  return (
    <IntlProvider locale={intlObj.getLocale()} messages={intlObj.getMessages()}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Chat {...paramsObj} />
    </IntlProvider>
  );
}, (prevProps, nextProps) => (JSON.stringify(prevProps) === JSON.stringify(nextProps)));

export default memo(ChatComponent);

/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file CustomMessage.jsx - A Component that sources custom header text for components
 * @author Arifhusain Soudagar
 */

import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ThemeConstants from '../Common/ThemeConstants';

const Styles = {
  headerMessageStyle: {
    display: 'block',
    fontSize: 16,
    fontFamily: 'TT Commons',
    margin: '16px 0',
    color: (props) => ThemeConstants[props.masterTheme][props.theme].textColor
  }
};

const CustomMessage = ({ classes, headerMessage }) => <Typography ariant="paragraph" className={classes.headerMessageStyle}>{headerMessage}</Typography>;

CustomMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  headerMessage: PropTypes.string
};

CustomMessage.defaultProps = {
  headerMessage: ''
};

export default withStyles(Styles)(injectIntl(CustomMessage));

/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file CopyIcon.jsx
 * @author Sarathkumar Venkatachalam
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';
import ThemeConstants from '../ThemeConstants';

const Styles = () => ({
  copyIcon: {
    width: 20,
    height: 20
  }
});

const CopyIcon = ({ classes, theme, masterTheme }) => (
  <SvgIcon
    fill="none"
    viewBox="0 0 20 20"
    classes={{
      root: classes.copyIcon
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9997 16.6667V17.9167C14.9997 19.0673 14.0669 20 12.9163 20H2.91634C1.76575 20 0.833008 19.0673 0.833008 17.9167V5.41667C0.833008 4.26607 1.76575 3.33333 2.91634 3.33333H4.16634V2.08333C4.16634 0.93274 5.09908 0 6.24967 0H14.6545C14.8755 0 15.0875 0.0877974 15.2438 0.244078L18.9223 3.92259C19.0785 4.07887 19.1663 4.29083 19.1663 4.51184V14.5833C19.1663 15.7339 18.2336 16.6667 17.083 16.6667H14.9997ZM13.333 16.6667H6.24967C5.09908 16.6667 4.16634 15.7339 4.16634 14.5833V5H2.91634C2.68622 5 2.49967 5.18655 2.49967 5.41667V17.9167C2.49967 18.1468 2.68622 18.3333 2.91634 18.3333H12.9163C13.1465 18.3333 13.333 18.1468 13.333 17.9167V16.6667ZM17.4997 14.5833V5H14.9997C14.5394 5 14.1663 4.6269 14.1663 4.16667V1.66667H6.24967C6.01956 1.66667 5.83301 1.85321 5.83301 2.08333V14.5833C5.83301 14.8135 6.01956 15 6.24967 15H17.083C17.3131 15 17.4997 14.8135 17.4997 14.5833Z"
      fill={ThemeConstants[masterTheme][theme].textColor}
    />
  </SvgIcon>
);

CopyIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.string,
  masterTheme: PropTypes.string
};

CopyIcon.defaultProps = {
  theme: 'default',
  masterTheme: 'plus'
};

export default withStyles(Styles)(CopyIcon);

/**
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2023 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */
/**
 * @file SummaryOption.jsx
 * @author Sarathkumar Venkatachalam
 */

/* eslint-disable */
import React, { useState } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Button
} from '@material-ui/core';
import messages from '../../defaultMessages';
import * as constants from '../../common/constants';
import ThemeConstants from '../../Common/ThemeConstants';

const styles = () => ({
summaryOptionContainer: {
    border: 'none',
    padding: 0,
    minHeight: 150,
    // background: '#e8eefa',
    borderRadius: '18px',
    boxSizing: 'border-box'
  },
  optionDescription: {
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.24px',
    color: props => ThemeConstants[props.masterTheme][props.theme].textColor,
    margin: 0,
    marginBottom: 8
  },
  sendStyle: {
    padding: '8px 24px',
    width: 88,
    height: 40,
    background: props => `${ThemeConstants[props.masterTheme][props.theme].bgColor} !important`,
    border: '1px solid #bcc1cb',
    borderRadius: '24px',
    textTransform: 'none',
    fontFamily: 'TT Commons',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    marginTop: 8,
    color: props => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  defaultRadio: {
    width: 20,
    height: 20,
    background: props => `url(${ThemeConstants[props.masterTheme][props.theme].defaultRadio}) center no-repeat !important`
  },
  selectedStyle: {
    // color: props => `${ThemeConstants[props.masterTheme][props.theme].selectedRadioBtnColor} !important`,
    width: 20,
    height: 20,
    background: props => `url(${ThemeConstants[props.masterTheme][props.theme].radioSelectedBG}) center no-repeat !important`
  },
  radioRootStyle: {
    padding: 5,
    color: props => ThemeConstants[props.masterTheme][props.theme].radioBtnColor,
    '&:hover': {
      background: props => `${ThemeConstants[props.masterTheme][props.theme].radioHoverColor} !important`
    },
    '&:focus': {
      background: props => `${ThemeConstants[props.masterTheme][props.theme].radioHoverColor} !important`
    },
    '&.Mui-focusVisible': {
      background: props => `${ThemeConstants[props.masterTheme][props.theme].radioHoverColor} !important`
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20
    }
  },
  labelStyle: {
    fontFamily: 'TT Commons',
    fontSize: 16,
    color: props => ThemeConstants[props.masterTheme][props.theme].textColor
  },
  selectedLabel: {
   fontWeight: '400 !important',
    color: props => `${ThemeConstants[props.masterTheme][props.theme].textColor} !important`
  },
  labelRootStyle: {
    padding: 0,
    display: 'flex',
    alignItems: 'center'
  },
  disabledButtonStyle:{
    opacity: '.5',
    color: props => `${ThemeConstants[props.masterTheme][props.theme].textColor} !important`
  }
});

const SummaryOptions = ({ 
    classes,
    onSummaryOptionClick,
    type,
    intl,
    pageRange,
    selectedChapter,
    pageIds,
    pageNums
  }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const { formatMessage } = intl;
  const handleChange = (event) => {
    const currentValue = event.target.value;
    setSelectedOption(currentValue);
  };

  const handleSend = () => {
    onSummaryOptionClick(type, selectedOption, pageRange, selectedChapter, pageIds, pageNums, null, null, true);
  };

  return (
    <fieldset className={classes.summaryOptionContainer}>
      <legend id="summaryOptions" className={classes.optionDescription}>{formatMessage(messages.summaryOptionTitle)}</legend>
      <RadioGroup role="none" name="summaryoptions" value={selectedOption} onChange={handleChange}>
        <FormControlLabel
          value={constants.BULLET}
          control={
            <Radio
              checked={selectedOption === constants.BULLET}
              classes={{
                root: classes.radioRootStyle
              }}
              icon={<span className={classes.defaultRadio} />}
              checkedIcon={<span className={classes.selectedStyle} />}
            />
          }
          label={formatMessage(messages.bullet)}
          classes={{
            root: classes.labelRootStyle,
            label: selectedOption === constants.BULLET ? `${classes.labelStyle} ${classes.selectedLabel}` : classes.labelStyle
          }}
        />
        <FormControlLabel
          value={constants.PARAGRAPH}
          control={
            <Radio
              checked={selectedOption === constants.PARAGRAPH}
              classes={{
                root: classes.radioRootStyle
              }}
              icon={<span className={classes.defaultRadio} />}
              checkedIcon={<span className={classes.selectedStyle} />}
            />
          }
          label={formatMessage(messages.paragraph)}
          classes={{
            root: classes.labelRootStyle,
            label: selectedOption === constants.PARAGRAPH ? `${classes.labelStyle} ${classes.selectedLabel}` : classes.labelStyle
          }}
        />
      </RadioGroup>
      <Button
        className={classes.sendStyle}
        onClick={handleSend}
        disabled={!selectedOption}
        classes={{ disabled: classes.disabledButtonStyle }}
      >
        {formatMessage(messages.send)}
      </Button>
    </fieldset>
  );
};

SummaryOptions.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  onSummaryOptionClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  selectedChapter: PropTypes.array,
  pageRange: PropTypes.object,
  theme: PropTypes.string.isRequired,
  masterTheme: PropTypes.string.isRequired,
  pageIds: PropTypes.array,
  pageNums: PropTypes.arryay
};

SummaryOptions.defaultProps = {
  selectedChapter: [],
  pageRange: '',
  pageIds: [],
  pageNums: []
};

export default withStyles(styles)(injectIntl(SummaryOptions));